import { combineReducers, configureStore} from '@reduxjs/toolkit'
import RoleReducer from './roleSlice'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const persistConfig = {
    key: 'root',
    storage,
  }
const rootReducer = combineReducers({
    currentRole:RoleReducer
})

  const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store= configureStore({
  reducer:persistedReducer
})

export const persistedStore = persistStore(store)