import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router-dom";
import { getUser } from "../config/user";
import ManageHospitals from "../assets/manageHospitals.svg";
import HospitalList from "../assets/hospitalList.svg";
import AddHospital from "../assets/addHospital.svg";
import ApproveHospital from "../assets/approveHospital.svg";
import GracePeriod from "../assets/gracePeriod.svg";

const Sidebarorg = () => {
  function sidebarElements(navigtiondata) {
    let elements = {};
    if (navigtiondata && navigtiondata.length > 0) {
      navigtiondata.forEach((nd) => {
        elements[`${nd?.navigation?.navigationName}`] =
          nd?.navigation?.navigationUrl;
      });
      console.log("elements:", elements);
      return elements;
    }
  }
  let user = getUser();
  let navElement = sidebarElements(user.navigationdata);
  console.log(navElement);
  let role = user?.roles[0]?.role?.roleName;
  console.log("user details:", user);
  const Navigate = useNavigate();
  return (
    <div
      style={{
        minHeight: "100vh",
        height: "inherit",
        fontFamily: "Inter",
        fontWeight: "400px",
        overflow: "scroll",
        backgroundColor: "white",
      }}
    >
      <Sidebar className="app">
        <Menu>
          <p
            style={{
              marginLeft: "20px",
              color: "#797979",
              fontSize: "11px",
              lineHeight: "24px",
            }}
          >
            MAIN
          </p>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#CDFAF4",
              width: "188px",
              height: "32px",
              borderRadius: "8px",
              marginLeft: "20px",
            }}
          >
            <GridViewOutlinedIcon
              style={{ color: "#017C57", marginRight: "10px" }}
            />
            <p
              style={{
                margin: 0,
                fontSize: "12px",
                color: "#017C57",
                fontSize: "12px",
              }}
            >
              Username Dashboard
            </p>
          </div>
          <div
            style={{
              width: "188px",
              height: "1px",
              marginTop: "16px",
              marginLeft: "16px",
              backgroundColor: "#E4E4E4",
            }}
          ></div> */}
          {/* ; */}
          &nbsp;
          {navElement["Hospital Dashboard"] !== undefined && (
            <MenuItem
              onClick={() => {
                user.user.hospitalId
                  ? Navigate("/dashboard")
                  : Navigate("/Dashboard/ManageCases");
              }}
              icon={<GridViewOutlinedIcon style={{ color: "#017C57" }} />}
              color="red"
            >
              {" "}
              Dashboard{" "}
              {user.user.hospitalId && (
                <div className="text-[10px] text-[#017C57]">Coming Soon</div>
              )}
            </MenuItem>
          )}
          <div
            style={{
              width: "188px",
              height: "1px",
              marginTop: "16px",
              marginLeft: "16px",
              backgroundColor: "#E4E4E4",
            }}
          ></div>
          <p
            style={{
              marginLeft: "20px",
              color: "#797979",
              fontSize: "11px",
              lineHeight: "24px",
            }}
          >
            MANAGEMENT
          </p>
          {navElement["Manage Hospitals"] !== undefined && (
            <SubMenu
              label="Manage Hospitals"
              icon={
                <img
                  src={ManageHospitals}
                  alt="manage-hospitals-icon"
                  height={20}
                  width={20}
                />
              }
            >
              {navElement["Hospital List"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/dashboard/hospitallist")}
                  icon={
                    <img
                      src={HospitalList}
                      height={20}
                      width={20}
                      alt="hospital-list-icon"
                    />
                  }
                >
                  {" "}
                  Hospial List{" "}
                </MenuItem>
              )}
              {navElement["Add Hospital"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/dashboard/addhospital")}
                  icon={
                    <img
                      src={AddHospital}
                      height={20}
                      width={20}
                      alt="add-hospital-icon"
                    />
                  }
                >
                  {" "}
                  Add Hospital{" "}
                </MenuItem>
              )}
              {navElement["Approve Hospital"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/dashboard/approvehospital")}
                  icon={
                    <img
                      src={ApproveHospital}
                      height={20}
                      width={20}
                      alt="approve-hospital-icon"
                    />
                  }
                >
                  {" "}
                  Approve Hospital{" "}
                </MenuItem>
              )}
              {navElement["Approve Hospital"] !== undefined && (
                <MenuItem
                  // onClick={() => Navigate("/dashboard/approvehospital")}
                  icon={
                    <img
                      src={GracePeriod}
                      height={20}
                      width={20}
                      alt="grace-period-icon"
                    />
                  }
                >
                  {" "}
                  Grace Period{" "}
                  <div className="text-[10px] text-[#017C57]">Coming Soon</div>
                </MenuItem>
              )}
            </SubMenu>
          )}
          {navElement["ManageSubAdmin"] !== undefined && (
            <SubMenu
              label="Manage staff"
              icon={<PeopleAltOutlinedIcon style={{ color: "#017C57" }} />}
            >
              {navElement["Sub Admin List"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/stafflist")}
                  icon={<ListOutlinedIcon style={{ color: "#017C57" }} />}
                >
                  {" "}
                  Staff List{" "}
                </MenuItem>
              )}
              {navElement["Add Sub Admin"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/addstaff")}
                  icon={<AddCircleOutlineIcon style={{ color: "#017C57" }} />}
                >
                  Add Staff
                </MenuItem>
              )}
              {navElement["Edit Sub Admin"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/dashboard/editsubadmin")}
                  icon={<EditNoteIcon style={{ color: "#017C57" }} />}
                >
                  Edit Staff
                </MenuItem>
              )}
            </SubMenu>
          )}
          {navElement["Manage Cases"] !== undefined && (
            <SubMenu
              label="Manage Cases"
              onClick={() => Navigate("/Dashboard/ManageCases")}
              icon={<ManageHistoryOutlinedIcon style={{ color: "#017C57" }} />}
            >
              {navElement["Active Cases"] !== undefined &&
                role !== "Hospital" && (
                  <MenuItem
                    onClick={() => Navigate("/dashboard/ActiveCases")}
                    icon={<ListOutlinedIcon style={{ color: "#017C57" }} />}
                  >
                    Active Cases
                  </MenuItem>
                )}
              {navElement["Add Case"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/addcases")}
                  icon={<NoteAddOutlinedIcon style={{ color: "#017C57" }} />}
                >
                  Add Case
                </MenuItem>
              )}
              {navElement["Map Documents"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/dashboard/mapdocuments")}
                  icon={
                    <DescriptionOutlinedIcon style={{ color: "#017C57" }} />
                  }
                >
                  Map Documents
                </MenuItem>
              )}
              {navElement["Upload Documents"] !== undefined && (
                <MenuItem
                  icon={<FileUploadOutlinedIcon style={{ color: "#017C57" }} />}
                >
                  Upload Documents
                </MenuItem>
              )}
              {navElement["Verify Documents"] !== undefined && (
                <MenuItem
                  icon={<TaskAltOutlinedIcon style={{ color: "#017C57" }} />}
                >
                  Verify Documents
                </MenuItem>
              )}
            </SubMenu>
          )}
          <div
            style={{
              width: "188px",
              height: "1px",
              marginTop: "16px",
              marginLeft: "16px",
              backgroundColor: "#E4E4E4",
            }}
          ></div>
          &nbsp;
          {navElement["Approve Cases"] !== undefined && (
            <>
              <MenuItem
                icon={
                  <CheckCircleOutlineOutlinedIcon
                    style={{ color: "#017C57" }}
                  />
                }
              >
                Approve Cases
              </MenuItem>
              <div
                style={{
                  width: "188px",
                  height: "1px",
                  marginTop: "16px",
                  marginLeft: "16px",
                  backgroundColor: "#E4E4E4",
                }}
              ></div>
            </>
          )}
          &nbsp;
          <p
            style={{
              marginLeft: "20px",
              color: "#797979",
              fontSize: "11px",
              lineHeight: "24px",
            }}
          >
            SCHEDULES
          </p>
          {navElement["Meeting Schedule"] !== undefined && (
            <MenuItem
              icon={<CalendarTodayOutlinedIcon style={{ color: "#017C57" }} />}
            >
              {" "}
              Meeting Schedule{" "}
              <div className="text-[10px] text-[#017C57]">Coming Soon</div>
            </MenuItem>
          )}
          {navElement["Meeting List"] !== undefined && (
            <MenuItem icon={<ListOutlinedIcon style={{ color: "#017C57" }} />}>
              Meeting List{" "}
              <div className="text-[10px] text-[#017C57]">Coming Soon</div>
            </MenuItem>
          )}
          {navElement["Schedule Call"] !== undefined && (
            <MenuItem
              icon={<PhoneInTalkOutlinedIcon style={{ color: "#017C57" }} />}
            >
              Scheduled Calls{" "}
              <div className="text-[10px] text-[#017C57]">Coming Soon</div>
            </MenuItem>
          )}
          {navElement["Schedule Chat"] !== undefined && (
            <>
              <MenuItem
                icon={
                  <MarkChatUnreadOutlinedIcon style={{ color: "#017C57" }} />
                }
              >
                Scheduled Chats{" "}
                <div className="text-[10px] text-[#017C57]">Coming Soon</div>
              </MenuItem>
              <div
                style={{
                  width: "188px",
                  height: "1px",
                  marginTop: "16px",
                  marginLeft: "16px",
                  backgroundColor: "#E4E4E4",
                }}
              ></div>
            </>
          )}
          &nbsp;
          <p
            style={{
              marginLeft: "20px",
              color: "#797979",
              fontSize: "11px",
              lineHeight: "24px",
            }}
          >
            CONTACT
          </p>
          {navElement["Contact Settlemed"] !== undefined && (
            <>
              <MenuItem
                icon={<PhoneInTalkOutlinedIcon style={{ color: "#017C57" }} />}
              >
                Contact Settlemed{" "}
                <div className="text-[10px] text-[#017C57]">Coming Soon</div>
              </MenuItem>
              <div
                style={{
                  width: "188px",
                  height: "1px",
                  height: "1px",
                  marginTop: "16px",
                  marginLeft: "16px",
                  backgroundColor: "#E4E4E4",
                }}
              ></div>
            </>
          )}
          &nbsp;
          {navElement["Notifications"] !== undefined && (
            <MenuItem
              icon={
                <NotificationsNoneOutlinedIcon style={{ color: "#017C57" }} />
              }
            >
              Notifications{" "}
              <div className="text-[10px] text-[#017C57]">Coming Soon</div>
            </MenuItem>
          )}
        </Menu>
      </Sidebar>
    </div>
  );
};
export default Sidebarorg;
