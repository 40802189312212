import React, { useState } from "react";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Paper } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import UploadDocumentModal from "./uploadDocumentModal";
import "./documentDetailBox.scss";
import RejectedModal from "./rejectedModal";
import ReviewDocumentModel from "./reviewDocumentModal";
import CountdownTimer from "../../../utils/countDownTimer";
import { useContext } from "react";
import { CaseContext } from "../contexts/CaseContexts";

const DocumentDetailBox = ({ docDetails }) => {
  let [documentDetails, setDocumentDetails] = useState(docDetails);
  const { roleName } = useContext(CaseContext);
  const statusBtnStyle = (() => {
    if (documentDetails?.status === "REJECTED") {
      return { color: "#CF3636", backgroundColor: "#FFD6DB" };
    } else if (documentDetails?.status === "INREVIEW") {
      return { color: "#017C57", backgroundColor: "#CDFAF4" };
    } else if (documentDetails?.status === "APPROVED") {
      return { color: "white", backgroundColor: "#017C57" };
    } else {
      return {};
    }
  })();

  return (
    <Paper className="document-detail-box">
      {/* {console.log(documentDetails)} */}
      <div className="model-heading">
        <div className="left-container">
          <div style={{ width: "10px", color: "#017C57" }}>
            <DescriptionOutlinedIcon />
          </div>
          <div className="w-[80%]">{documentDetails?.documentName}</div>
        </div>
        <button className="contained-btn" style={statusBtnStyle}>
          {documentDetails?.status}
        </button>
      </div>
      {/* <div className="mid-content">{documentDetails?.description}</div> */}
      {documentDetails?.status !== "REJECTED" ? (
        <div className="footer">
          {documentDetails?.documenturlmasters.length > 0 ? (
            <>
              <div className="uploaded-btn">
                Uploaded{" "}
                <CheckCircleOutlineOutlinedIcon sx={{ height: "16px" }} />
              </div>
              {documentDetails.status === "INREVIEW" &&
                (roleName === "TeamB" || roleName === "TeamC") && (
                  <ReviewDocumentModel
                    documentDetails={documentDetails}
                    setDocumentDetails={setDocumentDetails}
                  />
                )}
            </>
          ) : (
            roleName !== "TeamB" && (
              <>
                <UploadDocumentModal
                  documentDetails={documentDetails}
                  setDocumentDetails={setDocumentDetails}
                />
                {documentDetails?.deadline && (
                  <div className="countdown-container">
                    <div className="text-container">Time Remaining : </div>{" "}
                    &nbsp;
                    <div className="timer">
                      <CountdownTimer deadline={documentDetails.deadline} />
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </div>
      ) : (
        roleName !== "TeamB" && (
          <div className="footer-rejected">
            <RejectedModal documentDetails={documentDetails} />
          </div>
        )
      )}
    </Paper>
  );
};

export default DocumentDetailBox;
