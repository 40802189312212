import { useState, useEffect } from "react";
import JSZip from "jszip";
import { CaseServices } from "../../../services/CaseServices";
import DocumentIcon from "../../../assets/documentIcon.svg";
import DownloadIcon from "../../../assets/downloadIcon.svg";
import { logOut } from "../../../config/user";
const DownloadDocuments = ({ caseId }) => {
  const [mappedDocuments, setMappedDocuments] = useState(null);

  const handleDocDownload = async (urlData, documentName) => {
    const urls = urlData.map((url) => url.documentUrl);
    const zip = new JSZip();
    // const urls = [
    //   "https://images.unsplash.com/photo-1721332154191-ba5f1534266e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwxfHx8ZW58MHx8fHx8",
    //   "https://plus.unsplash.com/premium_photo-1684470563083-c9faf8ee47c0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwyfHx8ZW58MHx8fHx8",
    //   "https://images.unsplash.com/photo-1723117509226-9191b2c0f703?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw0fHx8ZW58MHx8fHx8 ",
    // ];
    const promises = urls.map(async (url) => {
      const res = await fetch(url);
      const blob = await res.blob();
      return blob;
    });
    const res = await Promise.all(promises);
    res.forEach((blob, index) => {
      zip.file(`${documentName}_${index}.jpg`, blob);
    });

    const zipFile = await zip.generateAsync({ type: "blob" });

    const a = document.createElement("a");
    a.download = `${documentName}.zip`;
    const url = URL.createObjectURL(zipFile);
    a.href = url;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const handleMultipleDownloads = (documentsData) => {
    console.log("documentsData:", documentsData);
    for (let doc of documentsData) {
      handleDocDownload(doc?.documenturlmasters, doc?.documentName);
    }
  };

  const getMappedDocuments = async () => {
    try {
      const response = await CaseServices.getMappedDocuments(caseId);
      console.log("response:", response.data.mappedDocuments);
      let data = [];

      for (let docType in response.data.mappedDocuments) {
        data.push({
          key: docType,
          value: response.data.mappedDocuments[docType],
        });
      }
      setMappedDocuments(data);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  useEffect(() => {
    getMappedDocuments();
  }, []);
  return (
    <div>
      {mappedDocuments?.map((document, idx) => {
        return (
          <div key={idx} className="mt-8">
            <div className="flex justify-between mb-2">
              <div className="text-xl font-semibold">{document.key}</div>
              {document?.value.length > 0 && (
                <div
                  className="flex gap-1 items-center text-white text-sm bg-[#017C57] rounded-full py-2 px-4 cursor-pointer"
                  onClick={() => handleMultipleDownloads(document?.value)}
                >
                  <img src={DownloadIcon} alt="download-icon" />
                  {`All ${document.key}`}
                </div>
              )}
            </div>

            <div
              style={{ borderBottom: "1px solid #E4E4E4", marginBlock: "20px" }}
            />
            {document?.value.length > 0 ? (
              <div className="grid grid-cols-4 gap-5">
                {document?.value?.map((doc, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{ border: "1.5px solid #E4E4E4" }}
                      className="h-[170px] flex flex-col justify-between rounded-[16px] p-6 "
                    >
                      <div className="text-xl text-[#191919] font-semibold font gap-2">
                        <img src={DocumentIcon} alt="document-icon" />{" "}
                        {doc.documentName}
                      </div>
                      <div
                        className="w-fit flex items-center gap-1 text-sm text-white font-semibold bg-[#017C57] rounded-full cursor-pointer px-4 py-2"
                        onClick={() =>
                          handleDocDownload(
                            doc?.documenturlmasters,
                            doc?.documentName
                          )
                        }
                      >
                        <img src={DownloadIcon} alt="download-icon" />
                        Download
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="text-center text-xl font-semibold pb-6">
                No Documents Mapped
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DownloadDocuments;
