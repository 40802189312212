import { useState, useEffect, useRef } from "react";
import NotificationImage from "../assets/notificationImage.png";
import SubNotificationImage from "../assets/subNotificationImage.png";
import { NotificationServices } from "../services/NotificationServices";
import { CircularProgress } from "@mui/material";
const NotificationPopUp = ({ setOpen, parentRef, updateCount }) => {
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [notificationData, setNotificationData] = useState(null);
  const [readStatus, setReadStatus] = useState("all");
  const [limit, setLimit] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const ref = useRef();

  const getTime = (createdDate) => {
    const currentDate = new Date();
    const diffInMs = currentDate - new Date(createdDate);

    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

    return `${days}D ${hours}H ${minutes}M ${seconds}S`;
  };
  const markNotificationStatus = async (notificationId, status) => {
    try {
      const response = await NotificationServices.markNotificationReadUnread(
        notificationId,
        status
      );
    } catch (error) {
      window.alert(error);
    }
  };
  const getUserNotification = async () => {
    setLoading(true);
    try {
      const response = await NotificationServices.getNotifications(
        readStatus,
        limit
      );
      console.log("notifications:", response);
      setNotificationData(response?.data?.UserNotifcations);
    } catch (error) {
      window.alert(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserNotification();

    const checkIfClickedOutside = (e) => {
      if (parentRef.current && parentRef.current.contains(e.target)) {
        setOpen(true);
      } else if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    document.body.style.overflow = "hidden";
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
      document.body.style.overflow = "auto";
    };
  }, []);
  useEffect(() => {
    getUserNotification();
  }, [readStatus]);
  return (
    <div
      style={{ border: "1px solid #E4E4E4" }}
      className="absolute top-[220%] -left-[95px] w-[380px] rounded-[8px] bg-white p-2 z-20"
    >
      <div className="flex justify-end gap-2 text-xs text-white mb-2">
        <div
          style={{ border: "1px solid #017C57" }}
          className={`${
            readStatus === true
              ? "text-white bg-[#017C57]"
              : "text-[#017C57] bg-white"
          } px-5 py-2 rounded-full`}
          onClick={() => setReadStatus(true)}
        >
          READ
        </div>
        <div
          style={{ border: "1px solid #017C57" }}
          className={`${
            readStatus === false
              ? "text-white bg-[#017C57]"
              : "text-[#017C57] bg-white"
          } px-5 py-2 rounded-full`}
          onClick={() => setReadStatus(false)}
        >
          UNREAD
        </div>
        <div
          style={{ border: "1px solid #017C57" }}
          className={`${
            readStatus === "all"
              ? "text-white bg-[#017C57]"
              : "text-[#017C57] bg-white"
          } px-5 py-2 rounded-full`}
          onClick={() => setReadStatus("all")}
        >
          ALL
        </div>
      </div>
      <div ref={ref}>
        {isLoading ? (
          <div>
            <CircularProgress />{" "}
          </div>
        ) : (
          <div className="max-h-[400px] overflow-y-scroll">
            {notificationData?.map((notification, idx) => {
              return (
                <div key={idx} className="flex items-start gap-[10px] p-2">
                  <img src={NotificationImage} alt="notification-image" />
                  <div
                    onClick={() => {
                      setSelectedNotification(idx);
                      if (!notification.read) {
                        markNotificationStatus(notification?.id, "true");
                        updateCount();
                      }
                    }}
                  >
                    <div
                      className={`text-sm text-[#191919] text-start ${
                        notification?.read ? "font-medium" : "font-semibold"
                      } tracking-tight`}
                    >
                      {notification?.title}
                    </div>
                    <div className="text-sm text-[#797979] text-start mt-1 tracking-tight">
                      {getTime(notification?.createdAt)}
                    </div>
                    {selectedNotification === idx && (
                      <div className="flex gap-[10px]">
                        {/* <div className="w-8 h-8" /> */}

                        <div className="flex items-start gap-4 p-2">
                          <img
                            src={SubNotificationImage}
                            alt="notification-image"
                          />
                          <div className="text-start">
                            <div className="text-sm text-[#191919] font-medium tracking-tight">
                              Details
                            </div>
                            <div className="text-sm text-[#797979] text-start mt-1 tracking-tight">
                              {notification?.body}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationPopUp;
