import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { number, object, string, date } from "yup";
import "./AddPatientDetails.scss";
import { emailRegex } from "../../../utils/formUtils";
import Select from "react-select";
import { State, City } from "country-state-city";
import { PatientServices } from "../../../services/PatientServices";
import { CaseServices } from "../../../services/CaseServices";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { logOut } from "../../../config/user";
const AddPatientDetails = ({
  setValue,
  setPatientId,
  hospitalid,
  functionalityType,
  patientDetails,
  refreshPage,
}) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateList, setStateList] = useState(null);
  const [cityList, setCityList] = useState(null);
  const [selectedState, setSelectedState] = useState(
    patientDetails?.state ? patientDetails.state : null
  );
  const [selectedCity, setSelectedCity] = useState(
    patientDetails?.city ? patientDetails.city : null
  );
  const currentRole = useSelector((state) => state.currentRole.value);

  const initialValues =
    functionalityType === "EDIT"
      ? {
          hospitalId: hospitalid,
          beneficiaryNo: patientDetails?.beneficiaryNo || "",
          patientName: patientDetails?.patientName || "",
          gender: patientDetails?.gender || "",
          age: patientDetails?.age || "",
          phone: patientDetails?.phone || "",
          emailId: patientDetails?.emailId || "",
          city: patientDetails?.city || "",
          state: patientDetails?.state || "",
          registeredDate: patientDetails?.registeredDate?.split("T")[0] || "",
        }
      : {
          hospitalId: hospitalid,
          beneficiaryNo: "",
          patientName: "",
          emailId: "",
          phone: "",
          gender: "",
          age: "",
          state: "",
          city: "",
          registeredDate: "",
        };

  const errorValidation = object({
    beneficiaryNo: string()
      .required("Required Field")
      .matches(
        /^[a-zA-Z0-9]{6,18}$/,
        "Beneficiary number must be an alphanumeric string with 6 to 18 characters"
      ),
    patientName: string()
      .matches(/^[a-zA-Z ]*$/, "Name must contain alphabets only")
      .min(3, "Name must be greater than 3 characters")
      .max(30, "Name must be less than 30 characters")
      .required("Required Field"),
    emailId: string().matches(emailRegex, "Invalid Email Address"),
    gender: string().required("Required Field"),
    age: number()
      .max(99, "Age should not be greater than 99")
      .required("Required Field")
      .positive()
      .integer(),
    state: string().required("Required Field"),
    city: string().required("Required Field"),
    registeredDate: date().required("Required Field"),
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const response = await PatientServices.addPatientDetails(values);
      if (response && response.data && response.data.patientId) {
        await CaseServices.addNewCase({
          patientId: response.data.patientId,
          hospitalId: hospitalid,
          status: "INACTIVE",
        });
        setPatientId(response.data.patientId);
        window.alert("Case Added");
        navigate("/dashboard/ActiveCases");
      } else {
        throw new Error("Invalid response or missing patientId");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  const handleUpdate = async (values) => {
    setIsSubmitting(true);
    try {
      await PatientServices.updatePatient(values, patientDetails?.id);
      await refreshPage();
      window.alert("Patient Detail Updated");
      setValue("CASE DETAILS");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    setStateList(State?.getStatesOfCountry("IN"));
  }, []);
  useEffect(() => {
    console.log("selected state:", selectedState);
    console.log("stateList", stateList);
    if (stateList) {
      if (typeof selectedState === "object") {
        setCityList(
          City.getCitiesOfState(
            selectedState?.countryCode,
            selectedState?.isoCode
          )
        );
      } else {
        const state = stateList?.filter(
          (state) => state.name === selectedState
        );
        console.log("state", state);
        setCityList(City.getCitiesOfState("IN", state[0].isoCode));
      }
    }
  }, [selectedState, stateList]);

  return (
    <div className="add-patient-container">
      <Formik
        initialValues={initialValues}
        validationSchema={errorValidation}
        onSubmit={functionalityType === "EDIT" ? handleUpdate : handleSubmit}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="heading">
              {functionalityType === "EDIT" ? "Edit " : ""}Patient Details
            </div>
            <hr
              style={{ color: "#E4E4E4", marginTop: "24px", width: "100%" }}
            />
            <div className="case-form-container1">
              <label className="label1">
                <div className="text-content">
                  Beneficiary No <span className="required">*</span>
                </div>
                <Field
                  className="input-field"
                  name="beneficiaryNo"
                  placeholder="Beneficiary No"
                  type="text"
                  onChange={(e) => {
                    const { value } = e.target;
                    // Allow only alphanumeric characters and limit to 12 characters
                    if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 18) {
                      setFieldValue("beneficiaryNo", value);
                    }
                  }}
                />
                {touched.beneficiaryNo && errors.beneficiaryNo && (
                  <div className="field-error">{errors.beneficiaryNo}</div>
                )}
              </label>
              <label className="label1">
                <div className="text-content">
                  Patient Name <span className="required">*</span>
                </div>
                <Field
                  className="input-field"
                  name="patientName"
                  placeholder="Patient Name"
                />
                {touched.patientName && errors.patientName && (
                  <div className="field-error">{errors.patientName}</div>
                )}
              </label>
              <label className="label1">
                <div className="text-content">Email ID</div>
                <Field
                  className="input-field"
                  name="emailId"
                  placeholder="Enter email address"
                />
                {touched.emailId && errors.emailId && (
                  <div className="field-error">{errors.emailId}</div>
                )}
              </label>
              <label className="label1">
                <div className="text-content">Phone</div>
                <Field
                  className="input-field"
                  name="phone"
                  maxLength="10"
                  placeholder="Enter phone number"
                />
                {touched.phone && errors.phone && (
                  <div className="field-error">{errors.phone}</div>
                )}
              </label>
              <label className="label1">
                <div className="text-content">
                  Gender <span className="required">*</span>
                </div>
                <Field
                  className="input-field"
                  name="gender"
                  as="select"
                  placeholder="Gender"
                >
                  <option value="">Select Gender</option>
                  <option value="MALE">MALE</option>
                  <option value="FEMALE">FEMALE</option>
                  <option value="OTHER">OTHER</option>
                </Field>
                {touched.gender && errors.gender && (
                  <div className="field-error">{errors.gender}</div>
                )}
              </label>
              <label className="label1">
                <div className="text-content">
                  Age <span className="required">*</span>
                </div>
                <Field
                  className="input-field"
                  name="age"
                  placeholder="Age"
                  type="number"
                />
                {touched.age && errors.age && (
                  <div className="field-error">{errors.age}</div>
                )}
              </label>
              <label htmlFor="state" className="label1">
                <div className="text-content">
                  State <span className="required">*</span>
                </div>
                <Select
                  name="state"
                  options={stateList}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={stateList?.filter(
                    (state) => state.name === values.state
                  )}
                  onChange={(item) => {
                    setSelectedState(item);
                    setFieldValue("state", item.name);
                  }}
                  placeholder="Select State"
                />
                {touched.state && errors.state && (
                  <div className="field-error">{errors.state}</div>
                )}
              </label>
              <label htmlFor="city" className="label1">
                <div className="text-content">
                  City <span className="required">*</span>
                </div>
                <Select
                  name="city"
                  options={cityList}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={cityList?.filter((city) => city.name === values.city)}
                  onChange={(item) => {
                    setSelectedCity(item);
                    setFieldValue("city", item.name);
                  }}
                  placeholder="Select City"
                />
                {touched.city && errors.city && (
                  <div className="field-error">{errors.city}</div>
                )}
              </label>
              <label className="label1">
                <div className="text-content">
                  Registered Date <span className="required">*</span>
                </div>
                <Field
                  className="input-field"
                  name="registeredDate"
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  min={
                    new Date(new Date().setDate(new Date().getDate() - 20))
                      .toISOString()
                      .split("T")[0]
                  }
                />
                {touched.registeredDate && errors.registeredDate && (
                  <div className="field-error">{errors.registeredDate}</div>
                )}
              </label>
            </div>
            <div className="button-box">
              {isSubmitting ? (
                <div className="flex justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <button className="fill-btn">SAVE AND PROCEED</button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddPatientDetails;
