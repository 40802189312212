import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./uploadDocumentModal.scss";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { CaseServices } from "../../../services/CaseServices";
import { docUploadRefreshContext } from "../contexts/CaseContexts";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { logOut } from "../../../config/user";
import CrossIcon from "../../../assets/crossIcon.svg";
import DownloadMultipleDocuments from "./DownloadMultipleDocuments";
import { CircularProgress } from "@mui/material";
import { CaseContext } from "../contexts/CaseContexts";
import PdfIcon from "../../../assets/pdfIcon.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "720px",
  width: "328px",
  height: "98%",
  borderRadius: "16px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  overflowY: "auto",
  p: 4,
};

export default function ReviewDocumentModel({ documentDetails }) {
  const [open, setOpen] = useState(false);
  const [openMultipleDownloads, setOpenMultipleDownloads] = useState(false);
  const refreshFunction = useContext(docUploadRefreshContext);
  const [rejectionReason, setRejectionReason] = useState();
  const [selectedImg, setSelectedImg] = useState(
    documentDetails?.documenturlmasters[0].documentUrl
  );
  const [isSubmitting, setSubmitting] = useState(false);
  const [approveRejectionModal, setApproveRejectionModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { roleName } = useContext(CaseContext);

  const ApproveDocument = async (docId, data) => {
    setSubmitting(true);
    try {
      await CaseServices.updateDocumentStatus(
        docId,
        { status: "APPROVED" },
        roleName
      );
      window.alert("Status approved");
      handleClose();
      await refreshFunction.getMappedDocuments();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setSubmitting(false);
  };

  const RejectDocument = async (docId, data) => {
    setSubmitting(true);
    try {
      await CaseServices.updateDocumentStatus(
        docId,
        {
          status: "REJECTED",
          rejectionReason: rejectionReason,
        },
        roleName
      );
      window.alert("Document Rejected");
      handleClose();
      await refreshFunction.getMappedDocuments();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setSubmitting(false);
  };

  const handleReject = () => {
    RejectDocument(documentDetails.id);
    setApproveRejectionModal(false);
  };
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="upload-modal-container">
      <button className="open-btn" onClick={handleOpen}>
        REVIEW
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="custom-modal"
        sx={{ zIndex: "40", marginTop: "96px" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width > 640 ? "720px" : "328px",
            height: "98%",
            borderRadius: "16px",
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Inter, sans-serif",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "24px",
                fontWeight: "600",
                color: "#191919",
              }}
            >
              Review {documentDetails?.documentName}
            </div>
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "16px",
                fontWeight: "400",
                color: "#797979",
                marginTop: "16px",
              }}
            >
              {documentDetails?.description}
            </div>
            <div className=" flex gap-2 justify-end  w-[90%] mb-2">
              <div
                onClick={() => {
                  // generatePdf(documentDetails?.documenturlmasters);
                  setOpenMultipleDownloads(true);
                }}
                style={{ border: "1px solid #E4E4E4" }}
                className="flex items-center gap-2 text-xs text-[#017C57] font-semibold py-2 px-4 tracking-wider rounded-full cursor-pointer"
              >
                <FileDownloadOutlinedIcon
                  className="text-[#017C57]"
                  sx={{ fontSize: "16px" }}
                />
                DOWNLOAD MULTIPLE
              </div>
              <div
                style={{ border: "1px solid #E4E4E4" }}
                className="flex justify-center items-center w-[34px] h-[34px] rounded-full"
              >
                <a href={selectedImg} download>
                  <FileDownloadOutlinedIcon
                    className="text-[#017C57]"
                    sx={{ fontSize: "18px" }}
                  />
                </a>
              </div>
            </div>
            <div className=" h-[90%] overflow-y-scroll">
              <div
                style={{
                  fontFamily: "inherit",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#797979",
                  marginTop: "40px",
                  // display: "flex",
                  // justifyContent: "center",
                }}
              >
                <div
                  style={{ border: "1px solid #E4E4E4" }}
                  className="relative flex justify-center rounded-[8px]"
                >
                  <div className="w-[296px] h-[360px] xl:w-[600px] xl:h-[640px] flex justify-center items-center p-5 ">
                    {selectedImg?.slice(-3) === "pdf" ? (
                      <iframe
                        src={`http://docs.google.com/gview?url=${selectedImg}&embedded=true`}
                        frameborder="0"
                        height="100%"
                        width="100%"
                      />
                    ) : (
                      <img
                        src={selectedImg}
                        className="rounded-[8px] w-[100%] h-[100%] object-contain "
                      />
                    )}
                  </div>
                </div>

                <div className="w-[100%] flex flex-wrap gap-2 mt-6 ">
                  {documentDetails?.documenturlmasters?.map((document, idx) => {
                    return (
                      <div
                        key={idx}
                        style={{ border: "1px solid #E4E4E4" }}
                        className="rounded-[8px] p-1 w-fit cursor-pointer"
                        onClick={() => setSelectedImg(document.documentUrl)}
                      >
                        {document?.documentUrl?.slice(-3) === "pdf" ? (
                          <img
                            src={PdfIcon}
                            alt="pdf-icon"
                            width={80}
                            height={80}
                          />
                        ) : (
                          <div className="w-[80px] h-[80px]">
                            <img
                              src={document.documentUrl}
                              width={80}
                              height={80}
                              alt="uploaded-image"
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  fontFamily: "inherit",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#797979",
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <textarea
                  type="text"
                  style={{
                    border: "1px solid #E4E4E4",
                    borderRadius: "8px",
                    height: "120px",
                    padding: "12px",
                  }}
                  className="w-[296px] xl:w-[600px]"
                  placeholder="Rejection reason"
                  onChange={(e) => {
                    setRejectionReason(e.target.value);
                  }}
                />
              </div>
              {isSubmitting ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "8px",
                    marginBottom: "30px",
                    marginTop: "16px",
                  }}
                >
                  <CircularProgress />{" "}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "8px",
                    marginBottom: "30px",
                  }}
                >
                  <button
                    style={{
                      borderRadius: "32px",
                      fontFamily: "Inter, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "12px",
                      backgroundColor: "white",
                      color: "#017C57",
                      border: "1px solid #017C57",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    // onClick={() => RejectDocument(documentDetails.id)}
                    className="h-[34px] w-[120px] xl:w-[160px] mt-4 "
                    onClick={() => setApproveRejectionModal(true)}
                  >
                    <CancelOutlinedIcon sx={{ height: "18px" }} /> REJECT
                  </button>

                  <button
                    style={{
                      borderRadius: "32px",
                      marginTop: "16px",
                      fontFamily: "Inter, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "12px",
                      backgroundColor: "#017C57",
                      color: "white",
                      border: "1px solid #017C57",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="h-[34px] w-[120px] xl:w-[160px] mt-4 "
                    onClick={() => ApproveDocument(documentDetails.id)}
                  >
                    <CheckCircleOutlinedIcon sx={{ height: "18px" }} /> APPROVE
                  </button>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
      {approveRejectionModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-[#017c5780] flex justify-center items-center z-[50]">
          <div className="relative w-[30%] h-[30%] flex justify-center items-center bg-white rounded-[16px] p-4">
            <div
              className="absolute top-[16px] right-[32px] cursor-pointer"
              onClick={() => setApproveRejectionModal(false)}
            >
              <img src={CrossIcon} alt="close-icon" />
            </div>
            <div>
              <p className="text-2xl font-semibold">
                Are you sure you want to reject.
              </p>
              <div className="flex justify-center items-center gap-2 mt-[10%]">
                <div
                  style={{ border: "1px solid #017C57" }}
                  className="w-[120px] text-xs text-[#017C57] font-semibold text-center  py-2 rounded-full cursor-pointer"
                  onClick={() => setApproveRejectionModal(false)}
                >
                  Go Back
                </div>
                <div
                  style={{ border: "1px solid #017C57" }}
                  className="w-[120px] text-xs text-white bg-[#017C57] font-semibold text-center py-2 rounded-full cursor-pointer"
                  onClick={handleReject}
                >
                  Yes
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {openMultipleDownloads && (
        <DownloadMultipleDocuments
          setOpen={setOpenMultipleDownloads}
          images={documentDetails?.documenturlmasters}
          documentName={documentDetails?.documentName}
        />
      )}
    </div>
  );
}
