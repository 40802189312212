import CloseIcon from "../../../assets/crossIcon.svg";
import DownloadIcon from "../../../assets/downloadIconGreen.svg";
import { CircularProgress } from "@mui/material";
const PreviewPdfModal = ({ documents, setOpen, isSubmitting, generatePDF }) => {
  return (
    <div className="fixed flex justify-center items-center top-0 left-0 w-screen h-screen bg-[#017C5780]">
      <div className="w-[50%] h-[85%] bg-white rounded-[16px] mt-16 p-8">
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => setOpen(false)}
        >
          <img
            src={CloseIcon}
            alt="close-icon"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="text-center text-[#191919] text-2xl font-semibold ">
          Preview Document
        </div>
        <div
          id="report"
          className="flex flex-col items-center h-[80%] overflow-y-scroll mt-12 p-4"
        >
          {documents?.map((document, idx) => {
            return document.isSelected ? (
              <div key={idx}>
                <img
                  src={document.documentUrl}
                  alt="document"
                  className="w-[100%]"
                />
              </div>
            ) : (
              <></>
            );
          })}
        </div>

        <div className="flex justify-center items-center">
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <div
              style={{ border: "1px solid #017C57" }}
              className="flex items-center gap-2 w-fit text-xs text-[#017C57] font-semibold tracking-wider mt-8 px-4 py-2 rounded-full cursor-pointer"
              onClick={() => {
                generatePDF(documents);
              }}
            >
              <img src={DownloadIcon} alt="download-icon" width={14} />
              DOWNLOAD AS PDF
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewPdfModal;
