
import React, { useState } from "react";
import { Loginpage } from "../login/loginpage";
import { Signuppage } from "../signup/signuppage";
import { ForgotpasswordSteps } from "./ForgotPasswordSteps";
import "./forgotpassword.scss";
import background from "../../background2.jpg";

const Forgotpassword = () => {
  const [signup, setSignup] = useState(false);

  return (
    <div className="home-container">
      <div
        className="background-image"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="yellow-overlay"></div>
        <div className="content"> 
        <div className="wholecontent">
          <div style={{display:'flex', flexDirection:"column", gap:150}}> 
          <text style={{fontSize:14,color:"#FFFFFF", fontFamily:"Inter", marginBottom:10}}>Settlemed Helps You Simplify Your Medical Claim Processing</text> 
          <div className="texts">
          
            <h2>1,000+ Hospitals</h2>
            <h2>50,000+ Happy Patients</h2>
            <h2>75,000+ Claim Settlements</h2>
            <hr className="horizontal-line"/>
      
          </div>
          </div>
          
          <div className="auth">
            {<ForgotpasswordSteps/>}
         
          </div>
          </div> 
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;
