import React from 'react';
import './Paymentdetails.scss'; // Assuming a separate CSS file for styles

const PaymentDetails = () => {
    return (
        <div className='main-box'>
            <div className="box1">
                <span className='payment'>Payment details</span>
                <hr style={{ width: '100%', color: 'rgba(228, 228, 228, 1)' }} />
                <h1 className='pay-with'>Pay with:</h1>
                <div className="payment-options">
                    <div className="payment-option">
                        <input type='checkbox' name="paymentMethod" className="Card-checkbox" />
                        <label>Card</label>
                    </div>
                    <div className="payment-option2">
                        <input type="checkbox" name="paymentMethod" className="bank-checkbox" />
                        <label className='payment-option2'>Bank</label>
                    </div>
                </div>
                <div className="card-details">
                    <label htmlFor="cardNumber" className='card-number-label'>Card Number</label>
                    <input id="cardNumber" name="cardNumber" className="card-number-input" />
                </div>
                <div style={{ display: 'flex', fontWeight: "bold", width: "100%" }}>
                    <div>
                        <span className='expiration-date'>Expiration Date</span>
                        <input name="expirationDate" className="input" placeholder="Expiration Date" />
                    </div>
                    <div style={{ paddingLeft: "12px", width: "80%" }}>
                        <span className='cvv'>CVV</span>
                        <input name="cvv" className="input2" placeholder="CVV" />
                    </div>
                </div>
                <div className="save-card-details-container">
                    <input type="checkbox" name="saveCardDetails" className="bullet-checkbox" />
                    <label className='save-card-details'>Save Card Details</label>
                </div>
                <div style={{ color: "rgba(121, 121, 121, 1)", marginTop: "20px" }}>
                    Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.
                </div>
            </div>
        </div>
    );
};

export default PaymentDetails;
