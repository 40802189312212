import React, { useEffect, useState } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { NotificationServices } from "../../services/NotificationServices";
import { HospitalServices } from "../../services/HospitalServices";
import Select from "react-select";
import { CircularProgress } from "@mui/material";
import "./manualNotification.scss";
const ManualNotification = () => {
  const [hospitalList, setHospitalList] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [selectedReceiver, setSelectedReceiver] = useState(null);
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const handleSubmit = async () => {
    setIsSending(true);
    const hospitalIds = selectedHospitals?.map((hospital) => hospital.id);
    const receivers = selectedReceiver?.map((receiver) => receiver.roleName);
    const data = {
      hospitalIds: hospitalIds,
      to: receivers,
      title: title,
      body: body,
    };
    if (selectedReceiver && selectedReceiver.length > 0) {
      try {
        const response = await NotificationServices.manualNotificationSend(
          data
        );
        console.log("notification send response:", response);
        window.alert("Message Sent Successfully");
      } catch (error) {
        window.alert(error);
      }
    } else {
      window.alert("Please select atleast one receiver");
    }
    setIsSending(false);
  };
  const getHospitals = async () => {
    try {
      const response = await HospitalServices.gethospitals("", 1, 1500);
      setHospitalList(response?.data?.list);
      console.log("response", response);
    } catch (error) {
      window.alert(error);
    }
  };
  const getRoleList = async () => {
    try {
      const response = await NotificationServices.getRoleList();
      setRoleList(response?.data?.allRoles);
      console.log("role list", response);
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    getHospitals();
    getRoleList();
  }, []);
  useEffect(() => {
    console.log("selectedHospitals:", selectedHospitals);
  }, [selectedHospitals]);
  useEffect(() => {
    console.log("selectedReceivers:", selectedReceiver);
  }, [selectedReceiver]);
  return (
    <div className="manage-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Profile &nbsp; &nbsp;/ &nbsp; &nbsp; Manual Notification
      </span>
      <text className="heading"> MANUAL NOTIFICATION</text>
      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
      <div
        style={{ border: "1px solid #e4e4e4", marginTop: "24px" }}
        className="rounded-[16px] p-5"
      >
        <div>
          <div className="flex justify-between">
            <div className="w-[48%]">
              <div className="mb-2">Select Hospitals</div>
              <Select
                options={hospitalList}
                getOptionLabel={(options) => {
                  return options["name"];
                }}
                getOptionValue={(options) => {
                  return options["id"];
                }}
                onChange={(values) => setSelectedHospitals(values)}
                isMulti
              />
            </div>
            <div className="w-[48%]">
              <div className="mb-2">Select Receivers</div>
              <Select
                options={roleList}
                getOptionLabel={(options) => {
                  return options["roleName"];
                }}
                getOptionValue={(options) => {
                  return options["roleName"];
                }}
                onChange={(values) => setSelectedReceiver(values)}
                isMulti
              />
            </div>
          </div>
          <div className="w-[48%] mt-8">
            <div className="mb-2">Subject</div>
            <div
              style={{ border: "1px solid #E4E4E4" }}
              className="w-full py-2 px-4 rounded-[4px]"
            >
              <input
                name
                type="text"
                placeholder="Please Enter Your Subject"
                className="w-full border-none outline-none"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="w-[48%] mt-8 ">
            <div className="w-full mb-2">Body</div>
            <div className="w-full">
              <textarea
                name="body"
                placeholder="Please Enter Your Message"
                style={{ border: "1px solid #E4E4E4" }}
                className="w-full py-2 px-4 rounded-[4px] outline-none"
                rows={10}
                onChange={(e) => setBody(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-12">
          {isSending ? (
            <CircularProgress />
          ) : (
            <div
              style={{ border: "1px solid #017C57" }}
              className="text-sm text-[#017C57] font-semibold px-10 py-2 rounded-full cursor-pointer"
              onClick={handleSubmit}
            >
              SUBMIT
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManualNotification;
