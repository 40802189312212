import { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import logo from "../../settlemedlogo.png";
import ProfileIcon from "../assets/userIcon.svg";
import BurgerIcon from "../assets/menuIcon.svg";
import CrossIcon from "../assets/crossIconGreen.svg";
import LoginIcon from "@mui/icons-material/Login";
import "./drawerappbar.scss";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationPopUp from "./NotificationPopUp";
import { NotificationServices } from "../services/NotificationServices";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import { useNavigate } from "react-router-dom";
import { getUser, logOut } from "../config/user";
import { useSelector } from "react-redux";
import MobileSidebar from "./MobileSidebar";
let navItems = ["What we do", "Subscription", "Our Products", "Contact Us"];

function DrawerAppBar(props) {
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [unreadNotificationData, setUnreadNotificationData] = useState(null);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const parentRef = useRef();
  const { Component, sidebar, loggedIn } = props;
  const userDetails = getUser();
  const currentRole = useSelector((state) => state.currentRole.value);
  const [anchorEl, setAnchorEl] = useState(null);
  const [osType, setOsType] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showSideBar, setShowSideBar] = useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
    setShowSideBar(!showSideBar);
  };

  const handlesubscription = () => {
    navigate("/dashboard/subscription");
  };
  const getUnreadNotification = async () => {
    try {
      const response = await NotificationServices.getNotifications(false);
      setUnreadNotificationData(response?.data?.total);
    } catch (error) {
      window.alert(error);
    }
  };
  const OSChecker = () => {
    const userAgent = navigator.userAgent;

    let os = "Unknown OS";
    if (userAgent.includes("Win")) {
      os = "windows";
    } else if (userAgent.includes("Mac")) {
      os = "macos";
    } else if (userAgent.includes("Linux")) {
      os = "linux";
    } else if (userAgent.includes("Android")) {
      os = "android";
    } else if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
      os = "ios";
    }

    setOsType(os);
  };
  useEffect(() => {
    //Below condition is to check whether userDetails has some value or not
    if (location.pathname !== "/") {
      getUnreadNotification();
    }
    OSChecker();
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        className="app-bar"
        style={{ backgroundColor: "white" }}
      >
        <Toolbar className="tool-bar">
          <Box
            style={{ maxWidth: "200px" }}
            sx={{
              flexGrow: 1,
              // display: { xs: "none", sm: "none", md: "block", lg: "block" },
            }}
            // onClick={handleDrawerToggle}
            onClick={() => {
              setShowSideBar(!showSideBar);
            }}
          >
            <img src={logo} style={{ height: "44px" }} alt="Settlemed logo" />
          </Box>
          {!loggedIn && (
            <>
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "block", lg: "block" },
                }}
              >
                {navItems.map((item) => (
                  <Button
                    key={item}
                    sx={{
                      color: "#797979",
                      fontFamily: "Inter sanaserif",
                      fontWeight: "400",
                    }}
                  >
                    {item}
                  </Button>
                ))}
              </Box>
              <Box
                style={{
                  maxWidth: "400px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button className="nav-outline-button">
                  Sign up/In{" "}
                  <LoginIcon
                    style={{
                      width: "16px",
                      height: "16px",
                      padding: "0px 3px",
                    }}
                  />
                </Button>
                <a
                  href={
                    osType === "android" || osType === "windows"
                      ? "https://play.google.com/store/apps/details?id=com.appc"
                      : "https://apps.apple.com/us/app/settlemed/id6639615396"
                  }
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                  }}
                  className="nav-filled-button"
                >
                  <LoginIcon
                    style={{
                      transform: "rotate(90deg)",
                      width: "16px",
                      height: "16px",
                      padding: "0px 3px",
                    }}
                  />
                  DOWNLOAD APP
                </a>
              </Box>
            </>
          )}

          {loggedIn && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  marginRight: "70px",
                  width: "350px",
                  height: "36px",
                  padding: "12px 16px",
                  border: "1px solid #E4E4E4",
                }}
                className="hidden xl:flex items-center gap-2 bg-white rounded-[8px]"
              >
                <SearchIcon style={{ color: "black" }} />
                <InputBase
                  className="searchInput"
                  type="text"
                  placeholder="Search anything"
                  aria-label="search anything"
                />
              </div>

              {/* Icons */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    width: "34px",
                    height: "34px",
                    borderRadius: "64px",
                    border: "1px solid #017C57",
                    marginRight: "16px",
                  }}
                  ref={parentRef}
                >
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="green"
                    style={{ padding: "4px" }}
                  >
                    <Badge
                      badgeContent={
                        unreadNotificationData ? unreadNotificationData : 0
                      }
                      color="error"
                      style={{ position: "relative" }}
                      onClick={() => {
                        console.log("Notification button is clicked");
                        setNotificationOpen(true);
                      }}
                    >
                      <NotificationsNoneIcon style={{ color: "#017C57" }} />
                      {isNotificationOpen && (
                        <NotificationPopUp
                          setOpen={setNotificationOpen}
                          parentRef={parentRef}
                          updateCount={getUnreadNotification}
                        />
                      )}
                    </Badge>
                  </IconButton>
                </div>
                <div
                  style={{
                    width: "34px",
                    height: "34px",
                    borderRadius: "64px",
                    border: "1px solid #017C57",
                  }}
                  className="hidden xl:block"
                >
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="green"
                    style={{ padding: "8px" }}
                  >
                    <ChatBubbleOutlineRoundedIcon
                      style={{
                        color: "#017C57",
                        width: "18px",
                        height: "18px",
                      }}
                    />
                  </IconButton>
                </div>
                <div className="block xl:hidden ">
                  <div
                    style={{ border: "1px solid #017C57" }}
                    className="w-[34px] h-[34px] flex items-center justify-center rounded-full ml-2"
                    onClick={() => {
                      setProfileOpen(!isProfileOpen);
                      setMenuOpen(false);
                    }}
                  >
                    <img src={ProfileIcon} alt="profile-icon" />
                  </div>
                </div>
                <div className="block xl:hidden">
                  <div
                    className="w-[34px] h-[34px] flex items-center justify-center rounded-full ml-2"
                    onClick={() => {
                      setMenuOpen(!isMenuOpen);
                      setProfileOpen(false);
                    }}
                  >
                    {isMenuOpen ? (
                      <img src={CrossIcon} alt="cross-icon" />
                    ) : (
                      <img src={BurgerIcon} alt="menu-icon" />
                    )}
                  </div>
                </div>
              </div>
              {/* Mobile Profile Menu */}
              {isProfileOpen && (
                <div className="fixed flex justify-end top-[94px] right-0 bg-[#017C5780] h-full w-full">
                  <div className="bg-white h-fit w-[90%] text-[#191919] p-2 rounded-s-[8px]">
                    <div className=" bg-[#F9F9F9] rounded-[8px] p-4">
                      <div className="flex items-center justify-between">
                        <div className="text-[#4B4B4B] text-sm font-medium ">
                          Name
                        </div>
                        <div className="text-[#4B4B4B] text-xs">
                          {userDetails?.user?.fullName}
                        </div>
                      </div>
                      <div className="flex items-center justify-between mt-4">
                        <div className="text-[#4B4B4B] text-sm font-medium ">
                          Role
                        </div>
                        <div className="text-[#4B4B4B] text-xs">
                          {userDetails?.roles[0]?.role?.roleName}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 px-2 pb-2">
                      <div
                        className="font-medium text-sm"
                        onClick={handlesubscription}
                      >
                        Subscription
                      </div>
                      <div
                        className="font-medium text-sm mt-2"
                        onClick={() => logOut()}
                      >
                        Logout
                      </div>
                    </div>
                  </div>{" "}
                </div>
              )}
              {/* Mobile Sidebar */}
              {isMenuOpen && (
                // <div className="fixed flex justify-end top-[94px] right-0 bg-[#017C5780] h-full w-full xl:hidden">
                //   <div className="bg-white h-full w-fit  text-[#191919] overflow-y-scroll  ">
                //     <div>{sidebar}</div>
                //   </div>
                // </div>
                <MobileSidebar />
              )}
              {/* Dropdown */}
              <div
                style={{
                  minWidth: "180px",
                  height: "48px",
                  borderRadius: "32px",
                  border: "1px solid #017C57",
                }}
                className="hidden xl:block"
              >
                {/* <div style={{ display: 'flex', alignItems:'center', justifyContent:'center'}}> */}
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#017C57",
                    height: "100%",
                    width: "100%",
                  }}
                  startIcon={
                    <div
                      style={{
                        height: "32px",
                        width: "32px",
                        borderRadius: "32px",
                        color: "#FFF",
                        background: "#E4E4E4",
                      }}
                    >
                      {userDetails.user.fullName
                        ? userDetails?.user?.fullName[0]
                        : ""}
                    </div>
                  }
                >
                  <div
                    style={{
                      height: "48px",
                      minWidth: "150px",
                      paddingBottom: "9px",
                    }}
                    className="flex flex-col gap-4px justify-center items-start"
                  >
                    <div style={{ height: "14px" }}>
                      {userDetails?.user?.fullName}
                    </div>
                    <div
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: 400,
                        textTransform: "none",
                        color: "#797979",
                        height: "12px",
                      }}
                    >
                      {currentRole?.role?.roleName}
                    </div>
                  </div>
                </Button>

                {/* </div> */}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handlesubscription}>Subscription</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={() => logOut()}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <nav>
        <div
          style={
            !showSideBar
              ? { display: "none" }
              : { height: "95%", marginTop: "94px" }
          }
          className="hidden xl:block"
        >
          {sidebar}
        </div>
      </nav>
      {Component}
    </Box>
  );
}

export default DrawerAppBar;
