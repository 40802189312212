import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './inactive.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 630,
  height: 342,
  borderRadius: '16px',
  border: '1px solid rgba(228, 228, 228, 1)',
  padding: '32px 48px 72px 48px',
  bgcolor: 'rgba(255, 255, 255, 1)',
  boxShadow: 24,
  outline: 0,
};

function App() {
  const [isInactive, setIsInactive] = useState(false);
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setIsInactive(event.target.checked);
    if (event.target.checked) {
      setOpen(true);
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <div className='inactivestaff-container'>
      <Switch
        checked={isInactive}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        sx={{
          width: '46px',
          height: '24px',
          padding: '0px',
          marginTop: '10px',
          marginLeft: '70px',
          borderRadius: '100px',
          '& .MuiSwitch-switchBase': {
            padding: '3px',
            '&.Mui-checked': {
              transform: 'translateX(22px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: 'rgba(1, 124, 87, 1)', // Background color when checked
              },
            },
          },
          '& .MuiSwitch-thumb': {
            width: '18px',
            height: '18px',
            boxShadow: 'none',
            backgroundColor: 'rgba(1, 124, 87, 1)', // Thumb color
          },
          '& .MuiSwitch-track': {
            borderRadius: '100px',
            backgroundColor: 'white', // Background color when unchecked
            opacity: 1,
            border: '1px solid rgba(1, 124, 87, 1)',
          },
        }}
      />
      <button className='deactivate-button' onClick={() => setIsInactive(true)}>Deactivate</button>
    
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              color: 'rgba(0, 0, 0, 0.54)',
            }}
          >
            <CloseIcon />
          </IconButton>
          <h2 className="modal-title" style={{ marginTop: '64px' }}>Are you sure you want to inactivate this Staff?</h2>
          <p className="description">Please confirm to proceed</p>
          <div className='buttons'>
            <button className='go' onClick={() => {
              setIsInactive(false);
              handleClose();
            }}> Go Back</button>
            <button className='deactivate' onClick={handleClose}>Deactivate</button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default App;
