import { useState, useEffect, useRef } from "react";
import CloseIcon from "../../../assets/crossIcon.svg";
import { PreinitiateServices } from "../../../services/PreinitiateServices";
import { AuthServices } from "../../../services/AuthServices";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { logOut } from "../../../config/user";
import DeleteIcon from "../../../assets/deleteIcon.svg";
import { CaseServices } from "../../../services/CaseServices";
import MapDocumentToUrl from "./MapDocumentToUrl";
import RejectionPopUp from "./RejectionPopUp";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import AddIcon from "../../../assets/AddMoreGreen.svg";
import PdfIcon from "../../../assets/pdfIcon.png";

const PreinitiateModal = ({ selectedCase, isOpen }) => {
  const [referenceDocuments, setReferenceDocuments] = useState(null);
  const [description, setDescription] = useState(null);
  const [docUrl, setDocUrl] = useState(null);
  const [sequence, setSequence] = useState(1);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isRejectionPopUp, setRejectionPopUp] = useState(false);
  const [openModalId, setOpenModalId] = useState(false);
  const fileInputRef = useRef(null);

  const currentRole = useSelector((store) => store.currentRole.value);
  console.log("current role:", currentRole.role.roleName);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const uploadImage = async (e) => {
    if (e.target.files[0].size > 5242880) {
      window.alert("Please upload a file smaller than 2 MB");
    } else {
      try {
        let data = new FormData();
        data.append("image", e.target.files[0]);
        let res = await AuthServices.uploadImage(data);
        // setUploadedImage(uploadedImageUrl);
        window.alert("Image uploaded successfully");
        setDocUrl(res?.data?.url);
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert(error);
        }
      }
    }
  };
  const getDocumentsData = async () => {
    setIsLoading(true);
    try {
      const response = await PreinitiateServices.getReferenceDocuments(
        selectedCase.id
      );
      setSequence(response?.data?.length + 1);
      setReferenceDocuments(response.data);
      console.log("preinitiate documents:", response);
    } catch (error) {
      window.alert(error);
    }
    setIsLoading(false);
  };

  const updateDocumentData = async () => {
    const data = {
      documentUrl: docUrl,
    };
    try {
      const response = await PreinitiateServices.updateReferenceDocument(
        data,
        selectedDoc.id
      );
      console.log("update response:", response);
      setSelectedDoc(null);
      setDocUrl(null);
      getDocumentsData();
    } catch (error) {
      window.alert(error);
    }
  };
  const deleteDocument = async (docId) => {
    try {
      const response = PreinitiateServices.deleteReferenceDocument(docId);
      console.log(response);
      getDocumentsData();
    } catch (error) {
      window.alert(error);
    }
  };

  const addDocument = () => {
    if (docUrl) {
      referenceDocuments
        ? setReferenceDocuments([
            ...referenceDocuments,
            {
              caseId: selectedCase.id,
              documentUrl: docUrl,
              sequence: sequence,
              description: description,
            },
          ])
        : setReferenceDocuments([
            {
              caseId: selectedCase.id,
              documentUrl: docUrl,
              sequence: sequence,
              description: description,
            },
          ]);
      setSequence(sequence + 1);
      setDescription(null);
      setDocUrl(null);
    }
  };
  const addDocumentsData = async () => {
    setIsSaving(true);
    try {
      const response = await PreinitiateServices.addReferenceDocuments(
        referenceDocuments
      );
      window.alert("Documents Added Successfully ");
      isOpen(false);
    } catch (error) {
      window.alert(error);
    }
    setIsSaving(false);
  };

  useEffect(() => {
    getDocumentsData();
  }, []);
  useEffect(() => {
    console.log("reference documents:", referenceDocuments);
  }, [referenceDocuments]);

  return (
    <div className="fixed top-5 left-0 w-screen h-screen flex justify-center items-center bg-[#017C5780] z-10">
      <div className="relative bg-white rounded-[16px] w-[70%] h-[80%] p-8">
        <div
          className="absolute top-[28px] right-[32px] cursor-pointer"
          onClick={() => isOpen(false)}
        >
          <img src={CloseIcon} height={30} width={30} alt="close-icon" />
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col items-center mt-8 overflow-y-scroll mb-4">
            <div className="text-[#191919] font-semibold text-2xl mb-4">
              Documents Added
            </div>
            <div className="text-[#797979]">Upload the documents here</div>
            {isLoading ? (
              <div className=" mt-12">
                <CircularProgress />{" "}
              </div>
            ) : (
              <div className="grid grid-cols-1 justify-center w-[80%] gap-4 my-8">
                {referenceDocuments &&
                  referenceDocuments.length > 0 &&
                  referenceDocuments?.map((doc, idx) => {
                    return (
                      <div
                        key={idx}
                        style={{ border: "1px solid #E4E4E4" }}
                        className="grid grid-cols-5 gap-2 items-center bg-[#F9F9F9] p-2 rounded-[8px]"
                      >
                        <div className="flex items-center">
                          {doc?.documentUrl?.slice(-3) === "pdf" ? (
                            <img
                              src={PdfIcon}
                              alt="pdf-icon"
                              width={48}
                              height={48}
                            />
                          ) : (
                            <div className="w-[80px] h-[80px]">
                              <img
                                src={doc.documentUrl}
                                alt={`${doc.description}-image`}
                                height={48}
                                width={48}
                              />
                            </div>
                          )}
                        </div>
                        <div className="font-semibold text-sm">
                          {" "}
                          {doc.description}
                        </div>
                        {currentRole?.role?.roleName
                          ?.toLowerCase()
                          .includes("hospital") && (
                          <button
                            style={{
                              height: "34px",

                              borderRadius: "32px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontFamily: "Inter, sans-serif",
                              fontWeight: "600",
                              fontSize: "14px",
                              backgroundColor: "#017c57",
                              color: "white",
                              border: "none",
                              gap: "8px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedDoc(doc);
                              handleButtonClick();
                            }}
                          >
                            <FileUploadOutlinedIcon fontSize="small" />{" "}
                            RE-UPLOAD
                          </button>
                        )}
                        {currentRole?.role?.roleName
                          ?.toLowerCase()
                          .includes("hospital") && (
                          <div
                            className="flex justify-center cursor-pointer"
                            onClick={() => deleteDocument(doc.id)}
                          >
                            <img
                              src={DeleteIcon}
                              alt="delete-icon"
                              width={20}
                            />
                          </div>
                        )}
                        {currentRole?.role?.roleName
                          ?.toLowerCase()
                          .includes("hospital") && (
                          <div
                            style={{ border: "1px solid #017C57" }}
                            className="text-sm text-[#017C57] font-semibold bg-white rounded-full px-4 py-2 cursor-pointer "
                            onClick={() => {
                              updateDocumentData();
                            }}
                          >
                            Save
                          </div>
                        )}
                        {currentRole?.role?.roleName
                          ?.toLowerCase()
                          .includes("team") && <div />}
                        {currentRole?.role?.roleName
                          ?.toLowerCase()
                          .includes("team") && (
                          <div
                            className="text-xs text-white bg-[#017C57] h-fit w-fit cursor-pointer rounded-full px-4 py-2"
                            onClick={() => setOpenModalId(idx)}
                          >
                            Map Document
                          </div>
                        )}

                        {currentRole?.role?.roleName
                          ?.toLowerCase()
                          .includes("team") && (
                          <div
                            className="text-xs text-white bg-[#017C57] h-fit w-fit cursor-pointer rounded-full px-4 py-2"
                            onClick={() => {
                              setSelectedDoc(doc);
                              setRejectionPopUp(true);
                              // handleRejectDocument();
                            }}
                          >
                            Review Document
                          </div>
                        )}
                        {isRejectionPopUp && (
                          <RejectionPopUp
                            isOpen={setRejectionPopUp}
                            selectedDoc={selectedDoc}
                          />
                        )}
                        {openModalId === idx && (
                          <MapDocumentToUrl
                            caseId={selectedCase.id}
                            selectedDoc={doc}
                            isOpen={setOpenModalId}
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>

          <div className="flex justify-center">
            <div
              style={{ border: "1px solid #E4E4E4" }}
              className="w-[80%] flex flex-col items-center gap-4 bg-[#F9F9F9] rounded-[16px] p-8 "
            >
              <div className="text-[18px] font-semibold text-[#191919]">
                Upload new document
              </div>
              <div>
                <input
                  type="file"
                  onChange={(e) => uploadImage(e)}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <button
                  style={{
                    height: "34px",
                    width: "192px",
                    borderRadius: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: "600",
                    fontSize: "14px",
                    backgroundColor: "#017c57",
                    color: "white",
                    border: "none",
                    gap: "8px",
                  }}
                  onClick={() => handleButtonClick()}
                >
                  <FileUploadOutlinedIcon fontSize="small" /> UPLOAD
                </button>
                <div className="text-xs text-[#797979] text-center mt-1">
                  Files Supported : .pdf, .png. jpg
                </div>
              </div>
              <div className="mt-4 w-[328px]">
                <input
                  type="text"
                  placeholder="Enter doc description"
                  value={description}
                  style={{ border: "1px solid #e4e4e4" }}
                  className="w-full h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] "
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div
                style={{ border: "1px solid #017C57" }}
                className="w-fit flex gap-2 text-sm text-[#017C57] font-semibold bg-white rounded-full px-4 py-2 cursor-pointer mt-4"
                onClick={addDocument}
              >
                <img src={AddIcon} alt="add-icon" />
                ADD DOCUMENT
              </div>
            </div>
          </div>
          <div>
            <input
              type="file"
              onChange={(e) => uploadImage(e)}
              style={{ display: "none" }}
              ref={fileInputRef}
            />

            <div className="flex justify-center mt-4">
              {isSaving ? (
                <CircularProgress />
              ) : (
                <div
                  className="text-white text-xs bg-[#017C57] font-semibold rounded-full px-10 py-2 cursor-pointer"
                  onClick={addDocumentsData}
                >
                  SAVE CHANGES
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreinitiateModal;
