import { useEffect, useState } from "react";
import PreviewPdfModal from "./PreviewPdfModal";
import CloseIcon from "../../../assets/crossIcon.svg";
import EyeIcon from "../../../assets/eyeIcon.svg";
import DownloadIcon from "../../../assets/downloadIconGreen.svg";
import DownloadedIcon from "../../../assets/downloadedIcon.svg";
import { AuthServices } from "../../../services/AuthServices";
import JsPDF from "jspdf";
import { CircularProgress } from "@mui/material";
const DownloadMultipleDocuments = ({ setOpen, images, documentName }) => {
  const [documents, setDocuments] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [nonDownloadedSelect, setNonDownloadedSelect] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pdfCount, setPdfCount] = useState(0);

  const handleSelection = (index) => {
    const modifiedData = documents?.map((document, idx) => {
      setSelectAll(false); // This is done to uncheck selectAll if any document is unchecked
      if (index === idx) {
        return { ...document, isSelected: !document.isSelected };
      } else {
        return document;
      }
    });
    setDocuments(modifiedData);

    //counting the selected documents
    const selectedDoc = modifiedData?.filter((data) => data.isSelected);
    setSelectedCount(selectedDoc.length);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setNonDownloadedSelect(false);
    if (selectAll) {
      const modifiedData = documents?.map((document) => ({
        ...document,
        isSelected: false,
      }));
      setDocuments(modifiedData);
      setSelectedCount(0);
    } else {
      const modifiedData = documents?.map((document) => ({
        ...document,
        isSelected: true,
      }));
      setDocuments(modifiedData);
      setSelectedCount(modifiedData.length);
    }
  };

  const handleNonDownloadedSelection = () => {
    setNonDownloadedSelect(!nonDownloadedSelect);
    setSelectAll(false);
    if (nonDownloadedSelect) {
      const modifiedData = documents?.map((document) => ({
        ...document,
        isSelected: false,
      }));
      setDocuments(modifiedData);
      setSelectedCount(0);
    } else {
      let selectCount = 0;
      const modifiedData = documents?.map((document) => {
        if (document.isDownloaded) {
          return { ...document, isSelected: false };
        } else {
          selectCount++;
          return { ...document, isSelected: true };
        }
      });
      setDocuments(modifiedData);
      setSelectedCount(selectCount);
    }
  };
  const generatePDF = async (images) => {
    setIsSubmitting(true);
    const selectedImages = images.filter((image) => image.isSelected);
    const imagesUrl = selectedImages.map((image) => image.documentUrl);

    //setting isDownloaded true for downloaded images
    const modifiedData = documents?.map((document) => {
      if (document.isSelected) {
        return { ...document, isDownloaded: true };
      } else {
        return document;
      }
    });
    setDocuments(modifiedData);
    try {
      let response = await AuthServices.loadImagesBase64({ images: imagesUrl });
      const report = new JsPDF("portrait", "pt", "a4");
      const margin = 0;
      const imgWidth = 595;
      const imgHeight = 845;

      let yPosition = 0;
      if (response?.data) {
        response.data.forEach((img, idx) => {
          if (idx !== 0) {
            report.addPage();
          }
          report.addImage(img, "JPEG", yPosition, margin, imgWidth, imgHeight);

          // yPosition += imgHeight + margin; // Update Y position for the next image
        });
      }
      report.save(`${documentName}_${pdfCount + 1}`);
      setPdfCount(pdfCount + 1);
    } catch (error) {
      window.alert(error);
    }
    setIsSubmitting(false);
  };
  useEffect(() => {
    const modifiedData = images.map((image) => ({
      ...image,
      isSelected: true,
      isDownloaded: false,
    }));
    setDocuments(modifiedData);
    setSelectedCount(modifiedData.length);
  }, []);
  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-[#017c5780] flex items-center justify-center z-50">
      <div
        style={{ border: "1px solid #E4E4E4" }}
        className="w-[50%] h-[70%] bg-white  py-8 px-12 rounded-[16px] overflow-hidden"
      >
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => setOpen(false)}
        >
          <img
            src={CloseIcon}
            alt="close-icon"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="text-2xl font-semibold text-center text-[#191919] mt-10">
          Select multiple documents & download them as a PDF
        </div>
        <div className="text-[#797979] mt-4 text-center">
          PDFs Downloaded {pdfCount}/5
        </div>
        <div className="flex justify-center items-center gap-4 mt-10">
          <div
            className="flex gap-1 items-center text-sm text-[#797979] font-semibold cursor-pointer"
            onClick={handleSelectAll}
          >
            <input
              type="checkbox"
              checked={selectAll}
              className="cursor-pointer"
            />{" "}
            Select All
          </div>
          <div
            className="flex gap-1 text-sm text-[#797979] font-semibold cursor-pointer"
            onClick={handleNonDownloadedSelection}
          >
            <input
              type="checkbox"
              checked={nonDownloadedSelect}
              className="cursor-pointer"
            />{" "}
            Select Non-downloaded
          </div>
          <div
            style={{ border: "1px solid #017C57" }}
            className="flex items-center gap-2 text-sm text-[#017C57] font-bold px-4 py-2 rounded-full cursor-pointer"
            onClick={() => setPreviewOpen(true)}
          >
            <img src={EyeIcon} alt="eye-icon" /> PREVIEW
          </div>
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <div
              style={{ border: "1px solid #017C57" }}
              className="flex items-center gap-2 text-sm text-[#017C57] font-bold px-4 py-2 rounded-full cursor-pointer"
              onClick={() => generatePDF(documents)}
            >
              <img src={DownloadIcon} alt="download-icon" />
              <div className="flex items-end gap-[2px]">
                DOWNLOAD{" "}
                <div className="text-[8px] text-[#464646] align-bottom">
                  {selectedCount} Selected
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-3 gap-2 mt-4 h-[350px] overflow-y-scroll">
          {documents?.map((image, idx) => {
            return (
              <div
                key={idx}
                className="group relative flex justify-center items-center w-[180px] h-[200px] bg-[#F9F9F9] rounded-[8px] overflow-hidden cursor-pointer"
                onClick={() => handleSelection(idx)}
              >
                {" "}
                <div>
                  <div className="flex justify-between items-center  mb-1 pt-2">
                    {image?.isDownloaded ? (
                      <div>
                        <div className="flex gap-1 items-center text-[10px] text-[#797979] font-semibold">
                          <img src={DownloadedIcon} alt="downloaded-icon" />{" "}
                          DOWNLOADED
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div>
                      <input
                        type="checkbox"
                        checked={image?.isSelected}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                  <img
                    src={image?.documentUrl}
                    alt="document-img"
                    className="object-contain h-[170px] w-[140px] group-hover:h-[190px] goup-hover:w-[140px] transition-all duration-500"
                  />
                </div>
                <div className="absolute -bottom-[100%] group-hover:bottom-0 left-0  w-full text-[#191919] text-[10px] text-center bg-[#E7FFFC] transition-all duration-300">
                  {" "}
                  Attachment Name
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {isPreviewOpen && (
        <PreviewPdfModal
          documents={documents}
          setOpen={setPreviewOpen}
          isSubmitting={isSubmitting}
          generatePDF={generatePDF}
        />
      )}
    </div>
  );
};

export default DownloadMultipleDocuments;
