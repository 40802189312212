import React from 'react';
import './Invoicedetails.scss'; // Assuming a separate CSS file for styles

const InvoiceDetails = () => {
    return (
        <div className='invoice-container'>
            <div className='custom-box'>
                <h2 className='Invoice'>Invoice Details</h2>
                <hr style={{ width: "100%", color: "rgba(228, 228, 228, 1)", marginTop: "50px" }} />
                <div className="label">
                    <span className='Email-id'>Email Id</span>
                    <input
                        name="email"
                        style={{ width: "704px", height: "40px", borderRadius: "8px", padding: "12px", border: "1px solid rgba(228, 228, 228, 1)" }}
                    />
                    <span className='mobile'>Mobile</span>
                    <input
                        name="mobile"
                        className="input"
                        style={{ width: "704px", height: "40px", borderRadius: "8px", padding: "12px", border: "1px solid rgba(228, 228, 228, 1)" }}
                    />
                    <span className='mobile'>Enter Your Full Name</span>
                    <input
                        name="fullName"
                        className="input"
                        style={{ width: "704px", height: "40px", borderRadius: "8px", padding: "12px", border: "1px solid rgba(228, 228, 228, 1)" }}
                    />
                  <hr style={{ width: "100%", color: "rgba(228, 228, 228, 1)", marginTop: "30px" }} />
                    <span className='mobile'>Hospital Name</span>
                    <input
                        name="hospitalName"
                        className="input"
                        style={{ width: "704px", height: "40px", borderRadius: "8px", padding: "12px", border: "1px solid rgba(228, 228, 228, 1)" }}
                    />
                    <div style={{ display: 'flex', fontWeight: "bold", width: "100%" }}>
                        <div>
                            <span className='expiration-date'>Country</span>
                            <input
                                name="country"
                                className="input"
                                placeholder="India"
                            />
                        </div>
                        <div style={{marginLeft:"10px"}}>
                            <span className='expiration-date'>City</span>
                            <input
                                name="city"
                                className="input"
                                placeholder="Uttar Prdesh"
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', fontWeight: "bold", width: "100%" }}>
                        <div>
                            <span className='expiration-date'>City</span>
                            <input
                                name="city"
                                className="input"
                                placeholder="City"
                            />
                        </div>
                        <div style={{marginLeft:"10px"}}>
                            <span className='expiration-date'>Pincode</span>
                            <input
                                name="city"
                                className="input"
                                placeholder="12345"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceDetails;
