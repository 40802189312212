import { useState, useEffect, useCallback } from "react";
import { onMessage, getMessaging } from "firebase/messaging";
import { getApp } from "firebase/app";

export function useMessageListener() {
  const [messageNotification, setMessageNotification] = useState(null);
  //   const [callAccepted, setCallAccepted] = useState(null);
  //   const [callRejected, setCallRejected] = useState(null);
  //   const [meetingCreated, setmeetingCreated] = useState(null);

  const messageHandler = useCallback((payload) => {
    console.log("Message received:", payload);
    setMessageNotification({
      title: payload?.notification?.title,
      body: payload?.notification?.body,
    });
  }, []);

  useEffect(() => {
    const app = getApp();
    const messaging = getMessaging(app);
    const unsubscribe = onMessage(messaging, messageHandler);
    return () => {
      if (unsubscribe && typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [messageHandler]);

  const resetMessageNotification = useCallback(
    () => setMessageNotification(null),
    []
  );

  return {
    messageNotification,
    resetMessageNotification,
  };
}
