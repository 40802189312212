import React, { useEffect, useState, useRef } from "react";
import UploadIcon from "../../assets/uploadIcon.svg";
import PreinitiateModal from "./preInitiateModal/PreinitiateModal";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import CustomPagination from "../../utils/paginationUtils";
import { logOut, logOut401 } from "../../config/user";
import { CaseServices } from "../../services/CaseServices";
import "./caseList.scss";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import CountdownTimer from "../../utils/countDownTimer";
import { useSelector } from "react-redux";

const CaseList = ({ caseStatus, token, caseId }) => {
  const [cases, setCases] = useState([1, 2, 3]);
  const [totalPages, setTotalPages] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [isPreinitiateOpen, setPreinitiateOpen] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const outsideUser = useSelector((state) => state.currentRole.isOutsideUser);
  const navigate = useNavigate();
  let searchTimeRef = useRef();
  let limit = 4;

  async function AllCases() {
    try {
      setIsLoading(true);
      let res = await CaseServices.allCases(
        searchKey,
        page,
        limit,
        caseStatus,
        caseId
      );
      setCases(res?.data?.caseList);
      setTotalPages(res?.data?.totalPages);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut401();
        navigate("/");
      } else {
        window.alert(error);
      }
      setIsLoading(false);
    }
  }

  const handleSearch = (e) => {
    //debouncing structure
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };

  const calculatePrcentage = (uploaded, total) => {
    let percent = 0;
    if (total !== 0) {
      percent = (uploaded / total) * 100;
    }
    return percent;
  };

  useEffect(() => {
    AllCases();
  }, [page, searchKey, caseStatus]);
  return (
    <div className="case-list-container">
      <Paper className="list-paper">
        <div className="heading">
          Total Cases
          <input
            type="text"
            className="input-search"
            onChange={handleSearch}
            placeholder="Search anything"
          />
        </div>
        <div className="sub-heading">
          Sort by
          <select
            className="outlined-btn"
            style={{ width: "194px", display: "block" }}
          >
            <option>TEAM - MANAGER</option>
          </select>
          <select
            className="outlined-btn"
            style={{ width: "162px", display: "block" }}
          >
            <option>CASE - STATUS</option>
          </select>
          <select
            className="outlined-btn"
            style={{ width: "143px", display: "block" }}
          >
            <option>HOSPITALS</option>
          </select>
        </div>
        <hr className="line" />
        <div className="overflow-x-scroll xl:overflow-x-auto">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{
                    borderBottom: 0,
                    whiteSpace: "nowrap", // Prevents text from wrapping to the next line
                    overflow: "hidden", // Hides overflow content
                    textOverflow: "ellipsis", // Adds ellipsis (...) if text overflows
                  }}
                >
                  Beneficiary No
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{
                    borderBottom: 0,
                    whiteSpace: "nowrap", // Prevents text from wrapping to the next line
                    overflow: "hidden", // Hides overflow content
                    textOverflow: "ellipsis", // Adds ellipsis (...) if text overflows
                  }}
                >
                  Patient name
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  HUID
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{
                    borderBottom: 0,
                    whiteSpace: "nowrap", // Prevents text from wrapping to the next line
                    overflow: "hidden", // Hides overflow content
                    textOverflow: "ellipsis", // Adds ellipsis (...) if text overflows
                  }}
                >
                  Hospital Name
                </TableCell>

                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  City
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  Gender
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  Age
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{
                    borderBottom: 0,
                    whiteSpace: "nowrap", // Prevents text from wrapping to the next line
                    overflow: "hidden", // Hides overflow content
                    textOverflow: "ellipsis", // Adds ellipsis (...) if text overflows
                  }}
                >
                  Registered Date
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                <>
                  {cases && cases.length > 0 ? (
                    cases?.map((cas, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <TableRow
                            style={
                              idx % 2 !== 0
                                ? {
                                    backgroundColor: "#F9F9F9",
                                    borderRedius: "8px",
                                  }
                                : {}
                            }
                          >
                            <TableCell colSpan="9">
                              <div className="cell-container">
                                <div className="left-container">
                                  <button
                                    className="filled-btn"
                                    style={
                                      cas?.masterstagestatuses &&
                                      cas?.masterstagestatuses[0] &&
                                      cas?.masterstagestatuses[0]
                                        ?.masterStageStatus === "INACTIVE"
                                        ? {
                                            backgroundColor: "#FFE81A",
                                            color: "#000000",
                                          }
                                        : {}
                                    }
                                  >
                                    {cas?.masterstagestatuses &&
                                      cas?.masterstagestatuses[0] &&
                                      cas?.masterstagestatuses[0]
                                        ?.masterStageStatus}
                                  </button>{" "}
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {cas?.submasterstagestatuses &&
                                      cas?.submasterstagestatuses[0] &&
                                      cas?.submasterstagestatuses[0]
                                        ?.commandCenterStage}
                                  </div>
                                  <div>
                                    {cas?.submasterstagestatuses &&
                                    cas?.submasterstagestatuses[0].nextStepUser
                                      ? cas?.submasterstagestatuses[0]
                                          .nextStepUser
                                      : "---"}
                                  </div>
                                  <FiberManualRecordRoundedIcon className="dot" />
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Manager Name
                                  </div>
                                </div>
                                {new Date(cas.deadline) == "Invalid Date" ? (
                                  <div className="right-container"></div>
                                ) : (
                                  <div className="right-container">
                                    <div>Time Remaining</div>
                                    <div>-</div>
                                    <div
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <CountdownTimer deadline={cas.deadline} />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className="table-body"
                            style={
                              idx % 2 !== 0
                                ? {
                                    backgroundColor: "#F9F9F9",
                                    borderRadius: "8px",
                                  }
                                : {}
                            }
                          >
                            <TableCell sx={{ borderBottom: 0 }} align="center">
                              {cas?.patient?.beneficiaryNo}
                            </TableCell>
                            <TableCell sx={{ borderBottom: 0 }} align="center">
                              {cas?.patient?.patientName}
                            </TableCell>
                            <TableCell sx={{ borderBottom: 0 }} align="center">
                              {cas?.hospitalId}
                            </TableCell>
                            <TableCell sx={{ borderBottom: 0 }} align="center">
                              {cas?.hospital?.name}
                            </TableCell>
                            <TableCell sx={{ borderBottom: 0 }} align="center">
                              {cas?.hospital?.city}
                            </TableCell>
                            <TableCell sx={{ borderBottom: 0 }} align="center">
                              {cas?.patient?.gender}
                            </TableCell>
                            <TableCell sx={{ borderBottom: 0 }} align="center">
                              {cas?.patient?.age}
                            </TableCell>
                            <TableCell sx={{ borderBottom: 0 }} align="center">
                              {cas?.patient?.registeredDate &&
                                cas?.patient?.registeredDate.split("T")[0]}
                            </TableCell>
                            <TableCell sx={{ borderBottom: 0 }} align="center">
                              <div
                                style={{
                                  display: "flex",
                                  gap: "32px",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{ width: "46px" }}
                                  onClick={() =>
                                    navigate("/dashboard/upload-documents", {
                                      state: { caseId: cas?.id },
                                    })
                                  }
                                >
                                  <CircularProgressbarWithChildren
                                    value={calculatePrcentage(
                                      cas?.totalDocuments -
                                        cas?.totalDuringTreatmentDocuments -
                                        cas?.admissionDocumentsPending -
                                        cas?.admissionDocumentsRejected -
                                        cas?.latestEnhancementPending -
                                        cas?.latestEnhancementRejected -
                                        cas?.onDischargePending -
                                        cas?.onDischargeRejected,
                                      cas?.totalDocuments -
                                        cas?.totalDuringTreatmentDocuments
                                    )} // value in percentage
                                    strokeWidth={13}
                                    styles={{
                                      path: {
                                        stroke: "#017C57",
                                        strokeLinecap: "round",
                                        transformOrigin: "center center",
                                      },
                                      trail: {
                                        stroke: "#CDFAF4",
                                        transformOrigin: "center center",
                                      },
                                    }}
                                  >
                                    {" "}
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        fontSize: "10px",
                                        color: "#017C57",
                                        fontFamily: "inter, sans-serif",
                                        fontWeight: 500,
                                        // paddingBottom: "14px",
                                      }}
                                    >
                                      {cas?.totalDocuments -
                                        cas?.totalDuringTreatmentDocuments -
                                        cas?.admissionDocumentsPending -
                                        cas?.admissionDocumentsRejected -
                                        cas?.latestEnhancementPending -
                                        cas?.latestEnhancementRejected -
                                        cas?.onDischargePending -
                                        cas?.onDischargeRejected}
                                      /
                                      {cas?.totalDocuments -
                                        cas?.totalDuringTreatmentDocuments}
                                    </div>
                                  </CircularProgressbarWithChildren>
                                </div>
                                <SaveAsOutlinedIcon
                                  className="icons"
                                  onClick={() => {
                                    outsideUser
                                      ? navigate("/Dashboard/EditCases/v2", {
                                          state: { selectedCase: cas },
                                        })
                                      : navigate("/Dashboard/EditCases", {
                                          state: { selectedCase: cas },
                                        });
                                  }}
                                />
                                <div
                                  style={{ border: "1px solid #017C57" }}
                                  className="flex  gap-2 px-2 py-2 rounded-full"
                                >
                                  <div className="text-[10px] h-[14px] w-[14px] text-[#FF1818] bg-[#FFC8C8] rounded-full">
                                    {cas?.referenceDocumentsRejected}
                                  </div>
                                  <div className="text-[10px] h-[14px] w-[14px] text-[#017C57] bg-[#CDFAF4] rounded-full">
                                    {cas?.totalReferenceDocuments}
                                  </div>
                                  <img
                                    src={UploadIcon}
                                    alt="upload-icon"
                                    className="w-[12px] cursor-pointer"
                                    onClick={() => {
                                      setPreinitiateOpen(true);
                                      setSelectedCase(cas);
                                    }}
                                  />
                                </div>
                                {/* <PostAddOutlinedIcon className="icons" /> */}
                                {/* <ModeEditOutlineOutlinedIcon className="icons" /> */}
                              </div>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan="9" align="center">
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan="9" align="center">
                    {" "}
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
      {isPreinitiateOpen && (
        <div>
          <PreinitiateModal
            selectedCase={selectedCase}
            isOpen={setPreinitiateOpen}
          />
        </div>
      )}
      <div className="list-pagination">
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default CaseList;
