import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Loginpage } from "./components/login/loginpage";
import { Signuppage } from "./components/signup/signuppage";
import DashBoard from "./components/dashboard/dashboard";
import { getUser } from "./components/config/user";
import DrawerAppBar from "./components/appbar/drawerappbar";
import Homepage from "./components/homepage/homepage";
import ActiveCasesDashboard from "./components/dashboard/cases/activeCasesDashboard";
import AddCasesDashboard from "./components/dashboard/cases/addcases/AddCasesDashboard";
import Sidebarorg from "./components/appbar/Sidebar";
import Subscriptionplan from "./components/Subscription/Subscriptionplan";
import Forgotpassword from "./components/forgotpassword/Forgotpassword";
import Renewsubscription from "./components/Subscription/renewsubscription/Renewsubscription";
import Teanmemberdashboard from "./components/teammemberdashboard/Teammemberdashboard";
import EditCasesDashboard from "./components/dashboard/cases/editCases/EditCasesDashboard";
import InitiateAdmission from "./components/hospitaldashboard/InitiateAdmission/InitiateAdmission";
import MapDocumentCaseList from "./components/dashboard/cases/mapdocuments/MapDocumentCaseList";
import SubscriptionBox from "./components/Subscription/Subscriptionplan";
import AddSubadmin from "./components/dashboard/Admin/Subadmin/AddSubadmin";
import EditSubadmin from "./components/dashboard/Admin/Subadmin/EditSubadmin";
import Subadminlist from "./components/dashboard/Admin/Subadmin/Subadminlist";
import PaymentDetails from "./components/Subscription/renewsubscription/Paymentdetails";
import InvoiceDetails from "./components/Subscription/renewsubscription/InvoiceDetails";
import Summary from "./components/Subscription/renewsubscription/Summary";
import InactiveStaff from "./components/inactivestaff/InactiveStaff";
import Hospitaldashboard from "./components/hospitaldashboard/Hospitaldashboard";
import CasewiseDashboard from "./components/hospitaldashboard/CasewiseDashboard";
import CaseStatus from "./components/hospitaldashboard/CaseStatus";
import DirectUploadDocUi from "./components/dashboard/cases/casedetails/DirectUploadDocUi";
import Lengthbar from "./components/hospitaldashboard/Lengthbar";
import Addhospital from "./components/addhospital/Addhospital";
import Hospitallist from "./components/hospitallist/Hospitallist";
import AddHospital from "./components/addhospital/Addhospital";
import ApproveHospital from "./components/approvehospital/ApproveHospital";
import Privacypolicy from "./components/privacypolicy/Privacypolicy";
import NotificationsStructure from "./components/notificationsStructure/NotificationsStructure";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Undersettled from "./components/hospitaldashboard/Undersettled";
import RevenueAnalytics from "./components/hospitaldashboard/RevenueAnalytics";
import AddDoctor from "./components/adddoctor/AddDoctor";
import ManageProcedure from "./components/dashboard/cases/manageProcedure/ManageProcedure";
import { AuthServices } from "./components/services/AuthServices";
import { useSelector } from "react-redux";
import { requestForToken, messaging } from "./components/services/Firebase";
import { useMessageListener } from "./components/utils/OnMessageListener";
import ManualNotification from "./components/dashboard/manualNotification/ManualNotification";
const PublicRoute = ({ ...props }) => {
  return (
    <>
      <ToastContainer />
      <DrawerAppBar />
      <Outlet />
      <footer />
    </>
  );
};
const PublicRoute1 = ({ ...props }) => {
  return (
    <>
      <Outlet />
    </>
  );
};

const PrivateRoute1 = ({ ...props }) => {
  let user = getUser();
  return !user?.session?.token ? (
    <Navigate replace to="/" />
  ) : (
    <>
      <DrawerAppBar
        sidebar={<Sidebarorg />}
        loggedIn={true}
        Component={<Outlet />}
      />
    </>
  );
};

function App() {
  const [token, setToken] = useState(null);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const currentRole = useSelector((state) => state.currentRole.value);
  const { messageNotification, resetMessageNotification } =
    useMessageListener();
  const updateFirebaseToken = async (fcm) => {
    const data = {
      firebaseId: fcm,
    };
    try {
      await AuthServices.updateFirebaseId(data);
    } catch (error) {
      console.log(error);
    }
  };
  const getRequestToken = async () => {
    try {
      const token = await requestForToken();
      setToken(token);
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    getRequestToken();
  }, []);
  useEffect(() => {
    if (token) {
      updateFirebaseToken(token);
    }
  }, [token]);
  useEffect(() => {
    if (messageNotification) {
      console.log("message notification:", messageNotification);
      toast.info(messageNotification.body);
    }
  }, [messageNotification]);
  return (
    <div className="main_container">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<Homepage />} />
          </Route>
          <Route path="/signup" element={<PublicRoute />}>
            <Route index element={<Signuppage />} />
          </Route>
          <Route path="/dashboard" element={<PrivateRoute1 />}>
            {currentRole?.role?.roleName === "Hospital" ? (
              <Route index element={<Hospitaldashboard />} />
            ) : (
              <Route index element={<DashBoard />} />
            )}
            <Route path="/dashboard/addhospital" element={<AddHospital />} />
            <Route path="/dashboard/hospitallist" element={<Hospitallist />} />
            <Route
              path="/dashboard/approvehospital"
              element={<ApproveHospital />}
            />
          </Route>
          <Route path="/dashboard/ActiveCases" element={<PrivateRoute1 />}>
            <Route
              path="/dashboard/ActiveCases"
              Component={ActiveCasesDashboard}
            />
          </Route>
          <Route path="/Dashboard/ManageCases" element={<PrivateRoute1 />}>
            <Route
              path="/Dashboard/ManageCases"
              Component={InitiateAdmission}
            />
          </Route>
          <Route path="/addcases" element={<PrivateRoute1 />}>
            <Route index element={<AddCasesDashboard />} />
          </Route>
          <Route path="/Subscriptionplan" element={<PrivateRoute1 />}>
            <Route index element={<Subscriptionplan />} />
          </Route>
          <Route path="/forgotpassword" element={<PublicRoute />}>
            <Route index element={<Forgotpassword />} />
          </Route>
          <Route path="/privacypolicty" element={<PublicRoute />}>
            <Route index element={<Privacypolicy />} />
          </Route>
          <Route path="/dashboard/adddoctor" element={<PrivateRoute1 />}>
            <Route index element={<AddDoctor />} />
          </Route>
          <Route path="/renewsubscription" element={<PublicRoute />}>
            <Route index element={<Renewsubscription />} />
          </Route>
          <Route
            path="/renewsubscription/paymentdetails"
            element={<PublicRoute />}
          >
            <Route index element={<PaymentDetails />} />
          </Route>
          <Route
            path="/renewsubscription/invoicedetails"
            element={<PublicRoute />}
          >
            <Route index element={<InvoiceDetails />} />
          </Route>
          <Route path="/renewsubscription/summary" element={<PublicRoute />}>
            <Route index element={<Summary />} />
          </Route>
          <Route path="/inactivestaff" element={<PublicRoute />}>
            <Route index element={<InactiveStaff />} />
          </Route>
          <Route path="/addhospital" element={<PublicRoute />}>
            <Route index element={<Addhospital />} />
          </Route>
          <Route path="/inactivestaff/homepage" element={<PublicRoute />}>
            <Route index element={<Homepage />} />
          </Route>
          <Route path="/teammemberdashboard" element={<PublicRoute />}>
            <Route index element={<Teanmemberdashboard />} />
          </Route>
          <Route path="/hospitaldashboard" element={<PrivateRoute1 />}>
            <Route index element={<Hospitaldashboard />} />
          </Route>
          <Route path="/hospitaldashboard/casewise" element={<PublicRoute />}>
            <Route index element={<CasewiseDashboard />} />
          </Route>
          <Route
            path="/hospitaldashboard/undersettled"
            element={<PublicRoute />}
          >
            <Route index element={<Undersettled />} />
          </Route>
          <Route
            path="/hospitaldashboard/revenueanalytics"
            element={<PublicRoute />}
          >
            <Route index element={<RevenueAnalytics />} />
          </Route>
          <Route path="/hospitaldashboard/lengthbar" element={<PublicRoute />}>
            <Route index element={<Lengthbar />} />
          </Route>
          <Route path="/hospitaldashboard/casestatus" element={<PublicRoute />}>
            <Route index element={<CaseStatus />} />
          </Route>
          <Route path="/dashboard/mapdocuments" element={<PrivateRoute1 />}>
            <Route index element={<MapDocumentCaseList />} />
          </Route>
          <Route path="/dashboard/manageprocedure" element={<PrivateRoute1 />}>
            <Route index element={<ManageProcedure />} />
          </Route>
          <Route path="/dashboard/subscription" element={<PrivateRoute1 />}>
            <Route index element={<SubscriptionBox />} />
          </Route>
          <Route path="/addstaff" element={<PrivateRoute1 />}>
            <Route index element={<AddSubadmin />} />
          </Route>
          <Route path="/dashboard/editsubadmin" element={<PrivateRoute1 />}>
            <Route index element={<EditSubadmin />} />
          </Route>
          <Route path="/stafflist" element={<PrivateRoute1 />}>
            <Route index element={<Subadminlist />} />
          </Route>
          <Route path="/dashboard/upload-documents" element={<PrivateRoute1 />}>
            <Route index element={<DirectUploadDocUi />} />
          </Route>
          <Route path="/Dashboard/EditCases" element={<PrivateRoute1 />}>
            <Route index element={<EditCasesDashboard />} />
          </Route>
          <Route
            path="/dashboard/manualNotification"
            element={<PrivateRoute1 />}
          >
            <Route index element={<ManualNotification />} />
          </Route>
          <Route
            path="/Dashboard/notificationsStructure"
            element={<PrivateRoute1 />}
          >
            <Route index element={<NotificationsStructure />} />
          </Route>
          <Route path="/Dashboard/ActiveCases/v2" element={<PublicRoute1 />}>
            <Route index element={<ActiveCasesDashboard />} />
          </Route>
          <Route path="/Dashboard/EditCases/v2" element={<PublicRoute1 />}>
            <Route index element={<EditCasesDashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
