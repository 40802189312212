import React from 'react';
import './Summary.scss'; // Assuming a separate CSS file for styles

const SideBox = () => {
    return (
        <div className="summary-container">
            <span className='plan-summary'>Plan Summary</span>
            <hr style={{ width: "100%", color: "rgba(228, 228, 228, 1)" }} />
            <div className="rectangle">
                <span className='Intro'>Intro (Renew Plan)</span>
                <span className='x1'>X 1</span>
            </div>
            <span className='per-month'>
                19000 <span className='month'>/month</span>
            </span>
            <hr style={{ width: "100%", color: "rgba(228, 228, 228, 1)" }} />
            <div className="card-details">
                <label htmlFor="promoCode" className='promo-code'>Enter Promo Code</label>
                <br />
                <div className='Rectangle2'>
                    <input
                        id="promoCode"
                        name="promoCode"
                        className="input-promo-code"
                        placeholder='Gift or discount code'
                    />
                    <button className='apply-button'>Apply</button>
                </div>
                <hr style={{ width:"100%",marginTop: "20px", color: "rgba(228, 228, 228, 1)" }} />
            </div>
            <div className='Rectangle3'>
                <span className='sub-total'>SubTotal</span>
                <span className='inr1'>19000 INR</span>
            </div>
            <div className='Rectangle3'>
                <span className='GST'>GST 18%</span>
                <span className='inr2'>3420 INR</span> {/* Adjusted to reflect 18% of 19000 */}
            </div>
            <hr style={{ width: "100%", color: "rgba(228, 228, 228, 1)" }} />
            <div className='Rectangle4'>
                <span className='Total'>Total</span>
                <span className='inr2'>22420 INR</span> {/* Adjusted to reflect total of 19000 + 3420 */}
            </div>
            <div className='Rectangle5'>
                <button className='apply-button2'>PAY 22,420 INR</button>
            </div>
            <div className='Rectangle6'>
                <span>
                    By purchasing, you accept the <span style={{ color: "rgba(1, 124, 87, 1)", fontWeight: "bold" }}>Terms of Use</span> and acknowledge reading the <span style={{ color: "rgba(1, 124, 87, 1)", fontWeight: "bold" }}>Privacy Policy</span>.
                </span>
            </div>
        </div>
    );
};

export default SideBox;
