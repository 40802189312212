import axios from "axios";
import { getAuthorizationToken } from "../config/user";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function addNewCase(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/addcase`,
    data: data,
    headers: getAuthorizationToken(),
  });
}
export const getUser = async () => {
  try {
    let user = localStorage.getItem("userDetails");
    if (user === null) {
      return { hospital: {}, user: {} };
    }
    return JSON.parse(user);
  } catch (error) {
    console.error("Error getting user data:", error);
    return { hospital: {}, user: {} };
  }
};

function addCaseProcedure(data, caseId) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/add-case-procedure/${caseId}`,
    data: data,
    headers: getAuthorizationToken(),
  });
}

function updateCaseProcedures(data, caseId) {
  return axios({
    method: "patch",
    url: `${REACT_APP_API_URL}/update-case-procedures/${caseId}`,
    data: data,
    headers: getAuthorizationToken(),
  });
}

function updateMappedDocuments(data, caseId) {
  return axios({
    method: "patch",
    url: `${REACT_APP_API_URL}/update-mapped-documents/${caseId}`,
    data: data,
    headers: getAuthorizationToken(),
  });
}

function mappingPendingCases(page, limit) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/mapping-pending-cases?page=${page}&limit=${limit}`,
    headers: getAuthorizationToken(),
  });
}

function defaultDocuments(procedureIds) {
  procedureIds = encodeURIComponent(JSON.stringify(procedureIds));
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/default-documents/${procedureIds}`,
    data: procedureIds,
    headers: getAuthorizationToken(),
  });
}

function allCases(searchKey, page, limit, caseStatus, caseId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/all-cases?searchKey=${searchKey}&page=${page}&limit=${limit}&status=${caseStatus}&caseId=${caseId}`,
    headers: getAuthorizationToken(),
  });
}

function getCaseDetails(caseId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/case-details/${caseId}`,
    headers: getAuthorizationToken(),
  });
}

function getCaseProcedures(caseId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/case-procedures/${caseId}`,
    headers: getAuthorizationToken(),
  });
}

function subscriptionall() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/subscription-plan`,
    headers: getAuthorizationToken(),
  });
}

function currentPlan() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/active-subscription-plan`,
    headers: getAuthorizationToken(),
  });
}

function addMapDocuments(data, caseId) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/add-map-document?caseId=${caseId}`,
    data: data,
    headers: getAuthorizationToken(),
  });
}

function getMappedDocuments(caseId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/mapped-document/${caseId}`,
    headers: getAuthorizationToken(),
  });
}

function saveDocumentUrl(documentId, documentUrl) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/save-document-url/${documentId}`,
    headers: getAuthorizationToken(),
    data: documentUrl,
  });
}

function updateCase(data, caseId) {
  return axios({
    method: "patch",
    url: `${REACT_APP_API_URL}/update-case/${caseId}`,
    data: data,
    headers: getAuthorizationToken(),
  });
}

function defaultSpecialityList(searchKey, page) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/default-specialities?searchKey=${searchKey}&page=${page}`,
    headers: getAuthorizationToken(),
  });
}

function defaultProcedureNameList(speciality, searchKey, page) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/default-procedure-list?speciality=${speciality}&searchKey=${searchKey}&page=${page}`,
    headers: getAuthorizationToken(),
  });
}

function updateDocumentStatus(documentId, data, currentRole) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/update-document-status/${documentId}?currentRole=${currentRole}`,
    data: data,
    headers: getAuthorizationToken(),
  });
}
function updateMultipleDocumentStatus(data, currentRole) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/multiple-documents-status-update?currentRole=${currentRole}`,
    data: data,
    headers: getAuthorizationToken(),
  });
}
function initiateAdmission(caseId) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/initiate-admission/?caseId=${caseId}`,
    headers: getAuthorizationToken(),
  });
}

function deleteDocument(documentId) {
  return axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/delete-mapped-document/${documentId}`,
    headers: getAuthorizationToken(),
  });
}
function defaultDocumentsWithoutGrouping(
  page,
  limit,
  searchKey,
  procedureId,
  speciality,
  procedure,
  specialityId,
  implants
) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/default-documents-without-grouping?searchKey=${searchKey}&page=${page}&limit=${limit}&procedureId=${procedureId}`,
    headers: getAuthorizationToken(),
  });
}
function defaultDocumentForSuggestionBox(search, page) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/default-documents-without-grouping?searchKey=${search}&page=${page}&limit=${10}`,
    headers: getAuthorizationToken(),
  });
}
function addEnhancement(data) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/add-case-master-details`,
    data: data,
    headers: getAuthorizationToken(),
  });
}
function getEnhancementsList(caseId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/case-enhancements/${caseId}`,
    headers: getAuthorizationToken(),
  });
}
function getLatestCaseStage(caseId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/latest-case-stage/${caseId}`,
    headers: getAuthorizationToken(),
  });
}

function updateSubMasterStage(caseId, data) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/sub-master-stage-update/${caseId}`,
    data: data,
    headers: getAuthorizationToken(),
  });
}

function getDefaultProcedureTypeList(procedureId, searchKey = "") {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/default-procedure-type-list?procedureId=${procedureId}&searchKey=${searchKey}`,

    headers: getAuthorizationToken(),
  });
}

function getProcedureCostList(compositId, city) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/procedure-cost-list?compositId=${compositId}&city=${city}`,
    headers: getAuthorizationToken(),
  });
}
function addDefaultProcedureCost(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/add-default-procedure-cost`,
    data: data,
    headers: getAuthorizationToken(),
  });
}

function procedureAllTierCost(compositId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/procedure-all-tier-cost?compositId=${compositId}`,
    headers: getAuthorizationToken(),
  });
}

function getDefaultProcedure(searchKey, page = 1, limit = 10) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/default-procedure?searchKey=${searchKey}&page=${page}&limit=${limit}}`,
    headers: getAuthorizationToken(),
  });
}

function addDefaultDocuments(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/add-update-default-documents`,
    headers: getAuthorizationToken(),
    data: data,
  });
}
function removeDefaultDocument(id) {
  return axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/delete-default-document/${id}`,
    headers: getAuthorizationToken(),
  });
}

function getDischarge(caseId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/discharge-details/${caseId}`,
    headers: getAuthorizationToken(),
  });
}
export const CaseServices = {
  saveDocumentUrl,
  addNewCase,
  addCaseProcedure,
  getCaseDetails,
  mappingPendingCases,
  defaultDocuments,
  allCases,
  getCaseProcedures,
  subscriptionall,
  currentPlan,
  addMapDocuments,
  getMappedDocuments,
  updateCase,
  updateCaseProcedures,
  updateMappedDocuments,
  defaultSpecialityList,
  defaultProcedureNameList,
  updateDocumentStatus,
  initiateAdmission,
  defaultDocumentsWithoutGrouping,
  deleteDocument,
  getEnhancementsList,
  addEnhancement,
  getLatestCaseStage,
  updateMultipleDocumentStatus,
  defaultDocumentForSuggestionBox,
  updateSubMasterStage,
  getDefaultProcedureTypeList,
  getProcedureCostList,
  addDefaultProcedureCost,
  procedureAllTierCost,
  getDefaultProcedure,
  addDefaultDocuments,
  removeDefaultDocument,
  getDischarge,
};
