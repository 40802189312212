import axios from "axios";
import { getAuthorizationToken } from "../config/user";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function getNotifications(readStatus, limit) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/user-notifications?readStatus=${readStatus}`,
    headers: getAuthorizationToken(),
  });
}

function markNotificationReadUnread(notificationId, status) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/mark-read-unread-notification/${notificationId}?read=${status}`,
    headers: getAuthorizationToken(),
  });
}

function getDefaultNotificationList(limit = 5, page = 1) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/default-notifications-list?limit=${limit}&page=${page}`,
    headers: getAuthorizationToken(),
  });
}
function updateDefaultNotifications(data) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/update-default-notification`,
    data: data,
    headers: getAuthorizationToken(),
  });
}
function manualNotificationSend(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/manual-notification-send`,
    data: data,
    headers: getAuthorizationToken(),
  });
}
function getRoleList() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/role-name-list`,
    headers: getAuthorizationToken(),
  });
}
export const NotificationServices = {
  getNotifications,
  markNotificationReadUnread,
  getDefaultNotificationList,
  updateDefaultNotifications,
  manualNotificationSend,
  getRoleList,
};
