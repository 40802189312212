import { useState } from "react";
import Select from "react-select";
import RemoveIcon from "../../../assets/removeIcon.svg";
import { Formik, Form, Field } from "formik";
import { number, object, string, date } from "yup";
import { CaseServices } from "../../../services/CaseServices";
import { useDispatch } from "react-redux";
import { selectedTab } from "../../../redux/roleSlice";
import { logOut } from "../../../config/user";

const errorValidation = object({
  days: number().required("Required Field"),
  fromDate: date().required("Required Field"),
  toDate: date().required("Required Field"),
  ward: string().required("Required Field"),
});
const AddEnhancementForm = ({
  daysOptions,
  wardOptions,
  caseProcedures,
  setAddEnhancement,
  caseId,
  enhancementNumber,
  setValue,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    days: "",
    fromDate: "",
    toDate: "",
    ward: "",
  };

  const handleSubmit = async (values) => {
    const data = [
      {
        detailType: "ENHANCEMENT",
        caseId: caseId,
        details: {
          enhancementDate: new Date(),
          fromDate: values.fromDate,
          toDate: values.toDate,
          wardType: values.ward,
          enhancementDays: values.days,
        },
        status: 1,
        version: 0,
      },
    ];
    try {
      const response = await CaseServices.addEnhancement(data);
      console.log(response);
      dispatch(selectedTab(null));
      setValue("MAP DOCUMENTS");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={errorValidation}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <div className="flex justify-between">
            <div className="font-semibold text-[#191919]">
              Enhancement {enhancementNumber}
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setAddEnhancement(false)}
            >
              <img src={RemoveIcon} alt="remove-icon" />
            </div>
          </div>
          <div className="flex justify-between gap-8  mt-8">
            <div className="w-1/3">
              <div className="text-sm text-[#191919] font-medium mb-4">
                1. For how many days would you like to initiate the enhancement?
              </div>

              <Select
                name="days"
                placeholder="Select Days"
                options={daysOptions}
                onChange={(item) => setFieldValue("days", item.value)}
              />
              {touched.days && errors.days && (
                <div className="field-error">{errors.days}</div>
              )}
            </div>
            <div className="w-1/3">
              <div className="text-sm text-[#191919] font-medium mb-4">
                2. From which date to which date should the enhancement last?
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <Field
                    type="date"
                    name="fromDate"
                    placeholder="--/--/---"
                    min={new Date().toISOString().split("T")[0]}
                    max = {
                      new Date(new Date().setDate(new Date().getDate() + Number(values.days)))
                        .toISOString()
                        .split("T")[0]
                    }
                
                    style={{ border: "1px solid #E4E4E4", width: "100%" }}
                    className="rounded-[8px] py-[10px] px-2"
                  />
                  {touched.fromDate && errors.fromDate && (
                    <div className="field-error">{errors.fromDate}</div>
                  )}
                </div>
                <div>
                  <Field
                    type="date"
                    name="toDate"
                    placeholder="--/--/---"
                    style={{ border: "1px solid #E4E4E4", width: "100%" }}
                    className="rounded-[8px] py-[10px] px-2"
                  />
                  {touched.toDate && errors.toDate && (
                    <div className="field-error">{errors.toDate}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/3">
              <div className="text-sm text-[#191919] font-medium mb-4">
                3 .In which ward would you like to take the enhancement?
              </div>
              <Select
                name="ward"
                placeholder="Select Ward"
                options={wardOptions}
                onChange={(item) => setFieldValue("ward", item.value)}
              />
              {touched.ward && errors.ward && (
                <div className="field-error">{errors.ward}</div>
              )}
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              style={{ border: "1px solid #017C57" }}
              className="w-fit text-[#017C57] bg-white rounded-full mt-12 px-4 py-2 border-0 cursor-pointer uppercase"
            >
              SAVE DETAILS
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddEnhancementForm;
