import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { CaseServices } from "../../../services/CaseServices";
import Modal from "@mui/material/Modal";
import "./mapDocumentsModal.scss";
import Select from "react-select";
import { Formik, Form, Field } from "formik";
import { logOut } from "../../../config/user";
import { object, string } from "yup";
import { CircularProgress } from "@mui/material";

export default function MapDocumentModal({
  documentType,
  caseProcedures,
  caseId,
  getDefaultsDocument,
}) {
  const [open, setOpen] = useState(false);
  const [required, setRequired] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initialValues = {
    procedure: "",
    procedureId: "",
    documentName: "",
  };

  const errorValidation = object({
    procedure: string().required("Required Field"),
    procedureId: string().required("Required Field"),
    documentName: string().required("Required Field"),
  });

  const handleSubmit = async (values) => {
    const postData = [
      {
        documentType: documentType.includes("admission")
          ? "ADMISSIONDOCUMENT"
          : documentType.includes("during")
          ? "DURINGTREATMENTDOCUMENT"
          : documentType.includes("discharge")
          ? "ONDISCHARGEDOCUMENT"
          : "",
        procedure: values.procedure,
        procedureId: values.procedureId,
        documentName: values.documentName,
        required: required,
      },
    ];
    try {
      setIsSubmitting(true);
      const response = await CaseServices.updateMappedDocuments(
        postData,
        caseId
      );
      getDefaultsDocument();
      window.alert("Documents updated successfully");
      handleClose();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    console.log("required", required);
  }, [required]);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <div className="map-modal-container">
      <button className="outline-btn" onClick={handleOpen}>
        <AddOutlinedIcon sx={{ height: "16px" }} />
        {documentType.toUpperCase()}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="custom-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width > 640 ? "720px" : "320px",
            height: "620px",
            borderRadius: "16px",
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Inter, sans-serif",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "24px",
                fontWeight: "600",
                color: "#191919",
              }}
            >
              Required Document
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={errorValidation}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form style={{ width: "100%" }}>
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#191919",
                      gap: "4px",
                      marginTop: "32px",
                      width: width > 640 ? "380px" : "100%",
                    }}
                  >
                    Procedure
                    <Select
                      name="procedure"
                      options={caseProcedures}
                      getOptionLabel={(options) => {
                        return options["procedure"];
                      }}
                      onChange={(item) => {
                        setFieldValue("procedure", item.procedure);
                        setFieldValue("procedureId", item.procedureId);
                      }}
                    />
                    {touched.procedure && errors.procedure && (
                      <div className="field-error">{errors.procedure}</div>
                    )}
                  </label>
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#191919",
                      gap: "4px",
                      marginTop: "16px",
                    }}
                  >
                    Procedure ID
                    <Field
                      type="text"
                      name="procedureId"
                      style={{
                        border: "1px solid #E4E4E4",
                        borderRadius: "8px",
                        paddingLeft: "12px",
                        width: width > 640 ? "380px" : "100%",
                        height: "40px",
                        fontFamily: "inherit",
                        color: "#797979",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                      disabled
                    />
                    {touched.procedureId && errors.procedureId && (
                      <div className="field-error">{errors.procedureId}</div>
                    )}
                  </label>

                  <label
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#191919",
                      gap: "4px",
                      marginTop: "16px",
                    }}
                  >
                    Document Name
                    <Field
                      type="text"
                      name="documentName"
                      style={{
                        border: "1px solid #E4E4E4",
                        borderRadius: "8px",
                        paddingLeft: "12px",
                        width: width > 640 ? "380px" : "100%",
                        height: "40px",
                        fontFamily: "inherit",
                        color: "#797979",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                      placeholder="Enter Document Name"
                      onChange={(e) => {
                        setFieldValue("documentName", e.target.value);
                      }}
                    />
                    {touched.documentName && errors.documentName && (
                      <div className="field-error">{errors.documentName}</div>
                    )}
                  </label>
                  <div
                    style={{
                      display: "flex",
                      width: "380px",
                      color: "#797979",
                      gap: "6px",
                      fontFamily: "inherit",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginTop: "24px",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{
                        height: "16px",
                        border: "1px solid #E4E4E4",
                      }}
                      onChange={() => setRequired(!required)}
                    />
                    Required
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {isSubmitting ? (
                      <CircularProgress />
                    ) : (
                      <button
                        type="submit"
                        style={{
                          height: "34px",
                          width: "160px",
                          borderRadius: "32px",
                          marginTop: "64px",
                          fontFamily: "Inter, sans-serif",
                          textAlign: "center",
                          fontWeight: "600",
                          fontSize: "12px",
                          backgroundColor: "white",
                          color: "#017C57",
                          border: "1px solid #017C57",
                        }}
                      >
                        SAVE
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
