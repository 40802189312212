import React from 'react';
// import phooto from './phooto.png';
import './renewsubscription.scss';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import PaymentDetails from './Paymentdetails';
import InvoiceDetails from './InvoiceDetails';
import Summary from './Summary'
const Renewsubscription = () => {
    return (
        <div className="renew-subscription-container">
            <div className="profile-text">/ &nbsp;&nbsp;Profile &nbsp;&nbsp;/ &nbsp;&nbsp; Subscription &nbsp;&nbsp;/ &nbsp;&nbsp;Renew Plan</div>
            <WidgetsOutlinedIcon style={{marginTop:"142px", marginLeft:"300px"}}/>
          
            <h1 className='renew-subscription'>Renew Subscription</h1>
            <hr style={{ width: '100%', color: 'rgba(228, 228, 228, 1)',marginTop:"1px" }} />
            <div className="main-container">
                
                    <PaymentDetails/>
                 
                    <Summary/>
                    
                    
                    
            </div>
            <InvoiceDetails/>
            </div>
            );
};

            export default Renewsubscription;
